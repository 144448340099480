import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import {checkUsername, getById, updateTeacher} from "../../http/UserApi";
import {Alert, Button, Card, Checkbox, ConfigProvider, DatePicker, Divider, Form, Input, Spin, Switch} from "antd";
import {LeftOutlined} from "@ant-design/icons";

export default function EditTeacher() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const params = useParams();

    const [oldUsername, setOldUsername] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [name, setName] = useState("");
    const [phoneTeacher, setPhoneTeacher] = useState("");
    const [linkVk, setLinkVk] = useState("");
    const [isActive, setIsActive] = useState(false);

    const [adding, setAdding] = useState(false);
    const [loading, setloading] = useState(true);
    const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
    const [alertErrorVisible, setAlertErrorVisible] = useState(false);

    const dateFormat = 'DD.MM.YYYY';

    useEffect(() => {
        getInfo();
    }, []);

    const checkPasswords = async (rule, value) => {
        try {
            if (password === password2) {
                return Promise.resolve();
            } else {
                return Promise.reject('Пароли не совпадают');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const checkUsernameAvailability = async (rule, value) => {
        try {
            const response = await checkUsername(value);
            if (response.data || username === oldUsername) {
                return Promise.resolve();
            } else {
                return Promise.reject('Имя пользователя уже занято');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const getInfo = async () => {
        await getById(localStorage.getItem('token'), params.id).then(
            response => {
                setUsername(response.data.username);
                setOldUsername(response.data.username);
                setName(response.data.name);
                setPhoneTeacher(response.data.phoneTeacher);
                setLinkVk(response.data.linkVk);
                setIsActive(response.data.isActive);
                setloading(false);
                console.log(response)
            }).catch(
            error => {
                console.log(error)
            }
        );
    };
    const handleSuccessClose = () => {
        setAlertSuccessVisible(false);
    };

    const handleErrorClose = () => {
        setAlertErrorVisible(false);
    };

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    const onFinish = async (values) => {
        setAdding(true);
        await updateTeacher(username, password, name, phoneTeacher, linkVk, isActive, params.id, localStorage.getItem('token')).then(
            response => {
                getInfo()
                setAlertSuccessVisible(true);
            }).catch(
            error => {
                setAlertErrorVisible(true);
                setAdding(false);
                console.log(error)
            }
        )
    };

    const handleSwitchChange = (checked) => {
        setIsActive(checked);
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{}}
                >
                    <Divider>Редактирование данных преподавателя</Divider>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Spin/>
                        </div>
                    ) : (
                        <Card
                            style={{display: "inline-block",}}
                        >
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                labelCol={{span: 11}}
                                wrapperCol={{span: 13}}
                                layout="horizontal"
                            >
                                <Form.Item
                                    name="username"
                                    label="Имя пользователя"
                                    initialValue={username}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите имя пользователя',
                                        },
                                        {
                                            validator: checkUsernameAvailability,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={event => {
                                            setUsername(event.target.value)
                                        }}
                                        onBlur={username.handleBlur}
                                        name="username"
                                        type="text"
                                        placeholder="Имя пользователя"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Пароль"
                                    initialValue={password}
                                >
                                    <Input.Password
                                        onChange={event => {
                                            setPassword(event.target.value)
                                        }}
                                        onBlur={password.handleBlur}
                                        name="password"
                                        type="password"
                                        placeholder="Пароль"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password2"
                                    label="Подтверждение пароля"
                                    initialValue={password}
                                    rules={[
                                        {
                                            validator: checkPasswords,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        onChange={event => {
                                            setPassword2(event.target.value)
                                        }}
                                        name="password2"
                                        type="password"
                                        placeholder="Подтверждение пароля"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label="ФИО"
                                    initialValue={name}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите ФИО',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={event => {
                                            setName(event.target.value)
                                        }}
                                        onBlur={name.handleBlur}
                                        name="name"
                                        type="text"
                                        placeholder="ФИО"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="linkVk"
                                    label="Ссылка на ВК"
                                    initialValue={linkVk}
                                >
                                    <Input
                                        onChange={event => {
                                            setLinkVk(event.target.value)
                                        }}
                                        onBlur={linkVk.handleBlur}
                                        name="linkVk"
                                        type="text"
                                        placeholder="Ссылка на ВК"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="phoneTeacher"
                                    label="Номер телефона"
                                    initialValue={phoneTeacher}
                                >
                                    <Input
                                        onChange={event => {
                                            setPhoneTeacher(event.target.value)
                                        }}
                                        name="phoneTeacher"
                                        type="text"
                                        placeholder="Номер телефона"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Активен" valuePropName="checked">
                                    <Switch checked={isActive} onChange={handleSwitchChange}/>
                                </Form.Item>
                                {adding ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Spin/>
                                    </div>
                                ) : (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{
                                            width: '100%',
                                        }}>
                                        Сохранить
                                    </Button>
                                )}
                            </Form>
                            {alertSuccessVisible && (
                                <Alert message="Успешно сохранено" type="success" closable
                                       afterClose={handleSuccessClose}
                                       style={{marginTop: '1vw',}}/>
                            )}
                            {alertErrorVisible && (
                                <Alert message="Ошибка сохранения" type="error" closable
                                       afterClose={handleErrorClose}
                                       style={{marginTop: '1vw',}}/>
                            )}
                        </Card>
                    )}
                </div>
            </div>
        </div>
    )
}