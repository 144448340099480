import React, {useState, useEffect} from 'react'
import {getAllStudentsByGroupId} from "../../http/UserApi";
import {add, checkDate} from "../../http/StudentLessonApi";
import {
    Button,
    Spin,
    Divider,
    Form,
    Card,
    Input,
    InputNumber,
    DatePicker,
    ConfigProvider,
    Table,
    Select,
    Alert
} from 'antd';
import {useParams, useNavigate} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import locale from "antd/es/locale/ru_RU";
import "dayjs/locale/ru";
import updateLocale from 'dayjs/plugin/updateLocale';

export default function AddLesson() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [date, setDate] = useState(dayjs());
    const [homework, setHomework] = useState("");
    const [lessonTheme, setLessonTheme] = useState("");
    const [lessonCost, setLessonCost] = useState(500);
    const [costVideo, setCostVideo] = useState(300);
    const [costOwn, setCostOwn] = useState(0);
    const [costOwn2, setCostOwn2] = useState(0);
    const [costOwn3, setCostOwn3] = useState(0);
    const [costOwn4, setCostOwn4] = useState(0);
    const [students, setStudents] = useState([]);

    const [selectedStatuses, setSelectedStatuses] = useState({});

    const [options] = useState([
        {
            label: 'Посещал занятие',
            value: 'ATTEND_LESSON',
        },
        {
            label: 'Смотрел видео',
            value: 'WATCH_VIDEO',
        },
        {
            label: 'Cвоя цена',
            value: 'OWN_PRICE',
        },
        {
            label: 'Cвоя цена 2',
            value: 'OWN_PRICE2',
        },
        {
            label: 'Cвоя цена 3',
            value: 'OWN_PRICE3',
        },
        {
            label: 'Cвоя цена 4',
            value: 'OWN_PRICE4',
        },
    ])

    const columns = [
        {
            title: 'Имя ученика',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Присутствие на занятии',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [adding, setAdding] = useState(false);
    const [alertErrorVisible, setAlertErrorVisible] = useState(false);

    const dateFormat = 'DD.MM.YYYY';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    const handleErrorClose = () => {
        setAlertErrorVisible(false);
    };

    const handleStatusChange = (studentId, status) => {
        setSelectedStatuses(selectedStatuses => ({
            ...selectedStatuses,
            [studentId]: status,
        }));
    };

    const checkDateAvailability = async (rule, value) => {
        try {
            const response = await checkDate(localStorage.getItem('token'), params.id, value);
            if (response.data) {
                return Promise.resolve();
            } else {
                return Promise.reject('Выбранная дата уже занята');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const getGroupStudents = async () => {
        await getAllStudentsByGroupId(localStorage.getItem('token'), params.id, true).then(
            response => {
                setStudents(
                    response.data.map(row => ({
                        key: row.student.id,
                        name: row.student.name,
                        status: <Select
                            style={{width: '100%'}}
                            placeholder="Выберите статусы"
                            defaultValue={options[0]}
                            optionFilterProp="label"
                            onChange={event =>
                                handleStatusChange(row.student.id, event)
                            }
                            options={options}
                        />
                    }))
                );
                response.data.forEach(row => {
                    handleStatusChange(row.student.id, options[0].value)
                });
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    useEffect(() => {
        getGroupStudents();
    }, []);

    const onFinish = async (values) => {
        setAdding(true);
        await add(localStorage.getItem('token'), params.id, date, homework, lessonTheme, lessonCost, costVideo, costOwn, costOwn2, costOwn3, costOwn4, selectedStatuses).then(
            response => {
                goBack()
            }).catch(
            error => {
                setAlertErrorVisible(true);
                setAdding(false);
                console.log(error)
            }
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div>
                    <Divider>Добавление занятия</Divider>
                    <Card
                        style={{display: "inline-block",}}
                    >
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{span: 10}}
                            wrapperCol={{span: 14}}
                            layout="horizontal"
                        >
                            <ConfigProvider locale={locale}>
                                <Form.Item
                                    label="Дата"
                                    name="date"
                                    initialValue={date}
                                    rules={[
                                        {
                                            validator: checkDateAvailability,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        name="date"
                                        format={dateFormat}
                                        onChange={event => {
                                            setDate(event.endOf('day'))
                                        }}
                                        allowClear={false}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </ConfigProvider>
                            <Form.Item
                                name="homework"
                                label="Домашнее задание"
                                initialValue={homework}
                            >
                                <Input.TextArea
                                    onChange={event => {
                                        setHomework(event.target.value)
                                    }}
                                    type="text"
                                    placeholder="Домашнее задание"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="lessonTheme"
                                label="Тема занятия"
                                initialValue={lessonTheme}
                            >
                                <Input.TextArea
                                    onChange={event => {
                                        setLessonTheme(event.target.value)
                                    }}
                                    type="text"
                                    maxLength={255}
                                    placeholder="Тема занятия"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="lessonCost"
                                label="Стоимость занятия"
                                initialValue={lessonCost}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setLessonCost(event)
                                    }}
                                    min={0}
                                    max={2147483647}
                                    suffix="₽"
                                    placeholder="Стоимость занятия"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            <Form.Item
                                name="costVideo"
                                label="Стоимость видео"
                                initialValue={costVideo}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setCostVideo(event)
                                    }}
                                    min={0}
                                    max={2147483647}
                                    suffix="₽"
                                    placeholder="Стоимость видео"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            <Form.Item
                                name="costOwn"
                                label="Своя cтоимость"
                                initialValue={costOwn}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setCostOwn(event)
                                    }}
                                    min={0}
                                    max={2147483647}
                                    suffix="₽"
                                    placeholder="Своя cтоимость"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            <Form.Item
                                name="costOwn2"
                                label="Своя cтоимость 2"
                                initialValue={costOwn2}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setCostOwn2(event)
                                    }}
                                    min={0}
                                    max={2147483647}
                                    suffix="₽"
                                    placeholder="Своя cтоимость 2"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            <Form.Item
                                name="costOwn3"
                                label="Своя cтоимость 3"
                                initialValue={costOwn3}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setCostOwn3(event)
                                    }}
                                    min={0}
                                    max={2147483647}
                                    suffix="₽"
                                    placeholder="Своя cтоимость 3"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            <Form.Item
                                name="costOwn4"
                                label="Своя cтоимость 4"
                                initialValue={costOwn4}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setCostOwn4(event)
                                    }}
                                    min={0}
                                    max={2147483647}
                                    suffix="₽"
                                    placeholder="Своя cтоимость 4"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            {loading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <Table
                                    columns={columns}
                                    dataSource={students}
                                    pagination={false}
                                    bordered
                                    style={{
                                        width: '100%',
                                        marginBottom: '1vw',
                                    }}
                                />
                            )}
                            {adding ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                    }}>
                                    Создать
                                </Button>
                            )}
                        </Form>
                        {alertErrorVisible && (
                            <Alert message="Ошибка создания" type="error" closable
                                   afterClose={handleErrorClose}
                                   style={{marginTop: '1vw',}}/>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    )
}