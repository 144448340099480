import {Button, Checkbox, ConfigProvider, Divider, Empty, Spin, Table} from "antd";
import {BookOutlined, LeftOutlined, StarOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {ADD_LESSON_ROUTE, EDIT_LESSON_ROUTE, LESSON_ROUTE, MY_STUDENT_ROUTE} from "../../utils";
import React, {useEffect, useState} from "react";
import {getNameById} from "../../http/GroupApi";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getAllByGroupId, getLessonsByGroupId, updateHomeworkIsDone} from "../../http/StudentLessonApi";
import dayjs from "dayjs";

export default function Homeworks() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const params = useParams();


    const [groupName, setGroupName] = useState('');
    const [columns, setColumns] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [homeworks, setHomeworks] = useState([]);
    const [loading, setLoading] = useState(true);

    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const dateFormat = 'DD.MM.YYYY';

    useEffect(() => {
        getName();
        getHistoryTitles();
    }, []);

    const getName = async () => {
        await getNameById(localStorage.getItem('token'), params.id).then(
            response => {
                setGroupName(response.data);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getHistoryTitles = async () => {
        await getLessonsByGroupId(localStorage.getItem('token'), params.id).then(
            response => {
                const lessonsTitles = [
                    {
                        title: 'Выполнение ДЗ',
                        dataIndex: 'student',
                        key: 'student',
                    },
                ];
                console.log(response)
                response.data.map((row, index) => {
                    let linkPath = index === 0 ? `${EDIT_LESSON_ROUTE}/${params.id}/${row.lessonNumber}` : `${LESSON_ROUTE}/${params.id}/${row.lessonNumber}`;
                    lessonsTitles.push({
                        title: <Link title={row.homework} to={linkPath}
                                     className="custom-link">{`Ур № ${row.lessonNumber} ${dayjs(row.date).format(dateFormat)} - ${daysOfWeek[dayjs(row.date).day()]}.`}</Link>,
                        dataIndex: row.lessonNumber,
                        key: row.lessonNumber,
                    });
                })
                setColumns(
                    lessonsTitles
                );
                getHistory()
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const onChange = async (event, key) => {
        await updateHomeworkIsDone(localStorage.getItem('token'), key, event.target.checked).then(
            response => {
                getHistoryTitles()
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getHistory = async () => {
        await getAllByGroupId(localStorage.getItem('token'), params.id).then(
            response => {
                const lessonsData = []
                response.data.forEach(element => {
                    const lessonObject = {
                        student: <Link to={`${MY_STUDENT_ROUTE}/${params.id}/${element.student.id}`}
                                       className="custom-link">{element.student.name}</Link>
                    }
                    element.studentLessons.forEach(
                        lesson => {
                            homeworks[lesson.id] = lesson.isDone;
                            lessonObject[lesson.lessonNumber] = <>
                                <Checkbox checked={lesson.isDone} onChange={(e) => onChange(e, lesson.id)}
                                          key={lesson.id}>Выполнил ДЗ</Checkbox>
                            </>
                        }
                    )
                    lessonsData.push(lessonObject)
                });
                setLessons(
                    lessonsData
                )
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <Divider>Домашние задания: {groupName}</Divider>
            <ConfigProvider
                renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет домашних заданий'/>}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={lessons}
                        pagination={false}
                        bordered
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                )}
            </ConfigProvider>
        </div>
    );
}