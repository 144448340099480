import {Api, authApi} from "./index";
import jwtDecode from "jwt-decode";


export const registration = async (username, password, name, phoneStudent, linkVk, city, birthday) => {
    const {data} = await Api.post(`auth/register`, {
        "username": username,
        "password": password,
        "name": name,
        "phoneStudent": phoneStudent,
        "linkVk": linkVk,
        "city": city,
        "birthday": birthday
    });
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token);
}

export const addTeacher = async (token, username, password, name, phoneTeacher, linkVk) => {
    return await Api.post(`user/add-teacher`, {
        "username": username,
        "password": password,
        "name": name,
        "phoneTeacher": phoneTeacher,
        "linkVk": linkVk,
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    });
}

export const login = async (username, password) => {
    const {data} = await Api.post(`auth/authenticate`, {"username": username, "password": password}) //ссылка для aвторизации пользователя
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const checkUsername = async (username) => {
    return await Api.get(`auth/check-username`, {
        params: {
            "username": username
        }
    })
}

export const check = async () => {
    const {data} = await authApi.get(`http`) //ссылка для проверки пользователя
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const getAllStudentsByGroupId = async (token, groupId, isActive) => {
    return await Api.get(`student-group/by-group`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "group_id": groupId,
            "is_active": isActive
        }
    })
}

export const getAllNewStudentsByGroupId = async (token, groupId) => {
    return await Api.get(`user`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "group_id": groupId,
            "not_in_group": true
        }
    })
}

export const getAllUsersByRole = async (token, role, page, size, sort, searchText) => {
    return await Api.get(`user/by-role`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "role": role,
            "page": page,
            "size": size,
            "sort": sort,
            "search": searchText
        }
    })
}

export const getAllTeachers = async (token) => {
    return await Api.get(`user/teachers`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getByCurrent = async (token) => {
    return await Api.get(`user/current`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getAllStudentBirthdays = async (token) => {
    return await Api.get(`user/birthdays`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getById = async (token, id) => {
    return await Api.get(`user/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const updateAdmin = async (username, password, name, linkVk, token) => {
    const {data} = await Api.put(`user/admin`, {
        "username": username,
        "password": password,
        "name": name,
        "linkVk": linkVk
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    localStorage.setItem('token', data.token)
    return data
}

export const updateTeacher = async (username, password, name, phoneTeacher, linkVk, isActive, userId, token) => {
    const {data} = await Api.put(`user/teacher`, {
        "username": username,
        "password": password,
        "name": name,
        "phoneTeacher": phoneTeacher,
        "linkVk": linkVk,
        "isActive": isActive
    }, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "user_id": userId,
        }
    })
    if (userId == null) {
        localStorage.setItem('token', data.token)
    }
    return data
}

export const updateStudent = async (username, password, name, phoneStudent, linkVk, city, birthday, userId, token) => {
    const {data} = await Api.put(`user/student`, {
        "username": username,
        "password": password,
        "name": name,
        "phoneStudent": phoneStudent,
        "linkVk": linkVk,
        "city": city,
        "birthday": birthday,
    }, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "user_id": userId,
        }
    })
    if (userId == null) {
        localStorage.setItem('token', data.token)
    }
    return data
}