import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import jwtDecode from "jwt-decode";
import {getAll, getAllByCurrentTeacher, graduate} from "../../http/GroupApi";
import {Alert, Button, ConfigProvider, Divider, Empty, Spin, Table} from "antd";
import {ADD_GROUP_ROUTE, EDIT_GROUP_ROUTE, LESSONS_ROUTE, MY_STUDENTS_ROUTE} from "../../utils";

export default function Groups() {

    const navigate = useNavigate();

    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alertVisible, setAlertVisible] = useState(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const [sort, setSort] = useState('isActive,desc');

    const handleClose = () => {
        setAlertVisible(false);
    };

    const columns = [
        {
            title: 'Название группы',
            dataIndex: 'name',
            key: 'name',
            sorter: true
        },
        {
            title: 'Технология',
            dataIndex: 'technology',
            key: 'technology',
            sorter: true
        },
        {
            title: 'Расписание',
            dataIndex: 'schedule',
            key: 'schedule',
            sorter: true
        },
        {
            title: 'Статус',
            dataIndex: 'isActive',
            key: 'isActive',
            sorter: true
        },
        {
            title: 'Занятия и оплаты',
            dataIndex: 'lessons',
            key: 'lessons',
            align: 'center',
        },
        {
            title: 'Редактировать группу',
            dataIndex: 'editGroup',
            key: 'editGroup',
            align: 'center',
        },
        {
            title: 'Выпустить группу',
            dataIndex: 'graduate',
            key: 'graduate',
            align: 'center',
        },
    ];

    function getSortOrder(order) {
        switch (order) {
            case 'ascend':
                return 'asc';
            case 'descend':
                return 'desc';
            default:
                return 'asc';
        }
    }

    const graduateGroup = async (id) => {
        await graduate(localStorage.getItem('token'), id).then(
            response => {
                getGroups();
                setAlertVisible(true);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getGroups = async () => {
        await getAll(localStorage.getItem('token'), pagination.current - 1, pagination.pageSize, sort).then(
            response => {
                setGroups(
                    response.data.content.map(row => ({
                        key: row.id,
                        name: <Link to={`${MY_STUDENTS_ROUTE}/${row.id}`}
                                    className="custom-link">{row.name} ({row.studentsCount} чел.)</Link>,
                        technology: row.technology,
                        schedule: row.schedule,
                        isActive: row.isActive ? "Активна" : "Выпущена",
                        lessons: <Link to={`${LESSONS_ROUTE}/${row.id}`} className="custom-link">Занятия</Link>,
                        editGroup: row.isActive ? <Button
                            onClick={() => navigate(`${EDIT_GROUP_ROUTE}/${row.id}`)}>Редактировать</Button> : "",
                        graduate: row.isActive ? <Button type="primary" danger
                                                         onClick={() => graduateGroup(row.id)}>Выпустить</Button> : ""
                    }))
                );
                setPagination({...pagination, total: response.data.totalElements});
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    useEffect(() => {
        getGroups();
    }, [pagination.current, pagination.pageSize, sort]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        if (sorter.column !== undefined && sorter.order !== undefined) {
            setSort(`${sorter.field},${getSortOrder(sorter.order)}`)
        } else {
            setSort("")
        }
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Divider>Группы</Divider>
            <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет групп'/>}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (

                    <Table
                        columns={columns}
                        dataSource={groups}
                        pagination={pagination}
                        bordered
                        onChange={handleTableChange}
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                )}
                {alertVisible && (
                    <Alert message="Группа успешно выпущена" type="success" closable afterClose={handleClose}
                           style={{margin: '1vw',}}/>
                )}
            </ConfigProvider>

        </div>
    )
}