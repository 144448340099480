import React, { useState, useEffect } from 'react'
import Footer from '../components/footerComponent/footer'
import EditLesson from '../components/teacherComponent/editLessonComponent'
import Header from '../components/headerComponent/header'
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SIGNIN_ROUTE } from '../utils';
import { isTokenValid } from '../utils/authUtils';

export default function EditLessonPage() {

  const navigate = useNavigate();
  let [isAuth, setAuth] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token != null && isTokenValid(token)) {
      setAuth(true);
    } else {
      localStorage.removeItem("token");
      setAuth(false);
      navigate(SIGNIN_ROUTE)
    }
  }, [navigate]);

  return (
    isAuth
      ?
      <Layout>
        <Header />
        <EditLesson />
        <Footer />
      </Layout>
      :
      <p />
  );
};