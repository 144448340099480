import React, { useState, useEffect } from 'react'
import { getAllByGroupIdAndLessonNumber } from "../../http/StudentLessonApi";
import { Button, Spin, Divider, Card, ConfigProvider, Table } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import locale from "antd/es/locale/ru_RU";
import "dayjs/locale/ru";
import updateLocale from 'dayjs/plugin/updateLocale';

export default function Lesson() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [lessonNumber, setLessonNumber] = useState('');
    const [date, setDate] = useState(dayjs());
    const [homework, setHomework] = useState("");
    const [lessonTheme, setLessonTheme] = useState("");
    const [lessonCost, setLessonCost] = useState(500);
    const [costVideo, setCostVideo] = useState(300);
    const [costOwn, setCostOwn] = useState(0);
    const [costOwn2, setCostOwn2] = useState(0);
    const [costOwn3, setCostOwn3] = useState(0);
    const [costOwn4, setCostOwn4] = useState(0);
    const [students, setStudents] = useState([]);

    const columns = [
        {
            title: 'Имя ученика',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Присутствие на занятии',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const params = useParams();

    const [loading, setLoading] = useState(true);

    const dateFormat = 'DD.MM.YYYY';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    function getStatusText(status) {
        switch (status) {
            case 'ATTEND_LESSON':
                return 'Посещал занятие';
            case 'WATCH_VIDEO':
                return 'Смотрел видео';
            case 'OWN_PRICE':
                return 'Занимался по особой цене';
            case 'OWN_PRICE2':
                return 'Занимался по особой цене 2';
            case 'OWN_PRICE3':
                return 'Занимался по особой цене 3';
            case 'OWN_PRICE4':
                return 'Занимался по особой цене 4';
            default:
                return 'Неизвестный статус';
        }
    }

    const getLesson = async () => {
        await getAllByGroupIdAndLessonNumber(localStorage.getItem('token'), params.id, params.number).then(
            response => {
                setStudents(
                    response.data.map(row => ({
                        key: row.student.id,
                        name: `${row.student.name} (${row.balanceLesson}₽ + ${row.bonusBalanceLesson}₽ бонусов)`,
                        status: getStatusText(row.paymentStatus)
                    }))
                );
                setLessonNumber(response.data[0].lessonNumber);
                setDate(dayjs(response.data[0].date));
                setHomework(response.data[0].homework);
                setLessonTheme(response.data[0].lessonTheme);
                setLessonCost(response.data[0].lessonCost);
                setCostVideo(response.data[0].costVideo);
                setCostOwn(response.data[0].costOwn);
                setCostOwn2(response.data[0].costOwn2);
                setCostOwn3(response.data[0].costOwn3);
                setCostOwn4(response.data[0].costOwn4);
                setLoading(false);
            }).catch(
                error => {
                    console.log(error)
                }
            );
    };

    useEffect(() => {
        getLesson();
    }, []);

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined /> Назад</Button>
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div>
                    <Divider>Занятие № {lessonNumber}</Divider>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Spin />
                        </div>
                    ) : (
                        <Card
                            style={{ display: "inline-block", }}
                        >
                            <ConfigProvider locale={locale}>
                                <Card
                                    style={{
                                        marginBottom: '1vw',
                                    }}
                                    type="inner"
                                    title="Дата занятия"
                                >
                                    {date.format(dateFormat)}
                                </Card>
                            </ConfigProvider>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Домашнее задание"
                            >
                                {homework}
                            </Card>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Тема занятия"
                            >
                                {lessonTheme}
                            </Card>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Стоимость занятия"
                            >
                                {lessonCost}₽
                            </Card>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Стоимость видео"
                            >
                                {costVideo}₽
                            </Card>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Особая cтоимость"
                            >
                                {costOwn}₽
                            </Card>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Особая cтоимость 2"
                            >
                                {costOwn2}₽
                            </Card>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Особая cтоимость 3"
                            >
                                {costOwn3}₽
                            </Card>
                            <Card
                                style={{
                                    marginBottom: '1vw',
                                }}
                                type="inner"
                                title="Особая cтоимость 4"
                            >
                                {costOwn4}₽
                            </Card>
                            <Table
                                columns={columns}
                                dataSource={students}
                                pagination={false}
                                bordered
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Card>
                    )}
                </div>
            </div>
        </div>
    )
}