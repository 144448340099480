import React, {useState, useEffect} from 'react'
import {createActive} from "../../http/GroupApi";
import {Button, Divider, Form, Card, Input, Alert, Spin} from 'antd';
import {useParams, useNavigate} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';


export default function AddGroup() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [name, setName] = useState("");
    const [technology, setTechnology] = useState("");
    const [schedule, setSchedule] = useState("");

    const params = useParams();

    const [adding, setAdding] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);

    const handleClose = () => {
        setAlertVisible(false);
    };

    useEffect(() => {
    }, []);

    const onFinish = async (values) => {
        setAdding(true);
        await createActive(name, technology, schedule, localStorage.getItem('token'), params.id).then(
            response => {
                goBack()
            }).catch(
            error => {
                setAlertVisible(true);
                setAdding(false);
                console.log(error)
            }
        )
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{}}
                >
                    <Divider>Создание группы</Divider>
                    <Card
                        style={{display: "inline-block",}}
                    >
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{span: 10}}
                            wrapperCol={{span: 14}}
                            layout="horizontal"
                        >
                            <Form.Item
                                label="Название группы"
                                name="name"
                                initialValue={name}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите название группы',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setName(event.target.value)
                                    }}
                                    type="text"
                                    placeholder="Название группы"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Технология"
                                name="technology"
                                initialValue={technology}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите технологию',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setTechnology(event.target.value)
                                    }}
                                    type="text"
                                    placeholder="Технология"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Расписание"
                                name="schedule"
                                initialValue={schedule}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите расписание',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setSchedule(event.target.value)
                                    }}
                                    type="text"
                                    placeholder="Расписание"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            {adding ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                    }}>
                                    Создать
                                </Button>
                            )}
                        </Form>
                        {alertVisible && (
                            <Alert message="Ошибка создания" type="error" closable
                                   afterClose={handleClose}
                                   style={{marginTop: '1vw',}}/>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    )
}
