import React, {useState, useEffect} from 'react'
import {getAllByIdAndCurrentStudent} from "../../http/StudentLessonApi";
import {getNameById} from "../../http/GroupApi";
import {Button, Table, Spin, Divider, ConfigProvider, Empty} from 'antd';
import {useParams, useNavigate} from 'react-router-dom';
import {LeftOutlined, VideoCameraOutlined, BookOutlined, StarOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';

export default function History() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const params = useParams();
    const columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
        },
        {
            title: 'Тема урока',
            dataIndex: 'lessonTheme',
            key: 'lessonTheme',
            width: 300,
        },
        {
            title: 'Домашнее задание',
            dataIndex: 'homework',
            key: 'homework',
            width: 500,
        },
        {
            title: 'Выполнение ДЗ',
            dataIndex: 'isDone',
            key: 'isDone',
        },
        {
            title: 'Оплата',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
        },
        {
            title: 'Стоимость урока',
            dataIndex: 'cost',
            key: 'cost',
        },
        {
            title: 'Баланс после списания',
            dataIndex: 'balanceLesson',
            key: 'balanceLesson',
        },
        {
            title: 'Бонусный баланс после списания',
            dataIndex: 'bonusBalanceLesson',
            key: 'bonusBalanceLesson',
        },
    ];

    const [lessons, setLessons] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [loading, setLoading] = useState(true);

    const dateFormat = 'DD.MM.YYYY';

    useEffect(() => {
        getHistory();
        getName();
    }, []);

    const getName = async () => {
        await getNameById(localStorage.getItem('token'), params.id).then(
            response => {
                setGroupName(response.data);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getHistory = async () => {
        await getAllByIdAndCurrentStudent(localStorage.getItem('token'), params.id).then(
            response => {
                setLessons(
                    response.data.map(row => ({
                        date: dayjs(row.date).format(dateFormat),
                        lessonTheme: row.lessonTheme,
                        homework: row.homework,
                        isDone: row.isDone ? "Выполнено" : "Не выполнено",
                        paymentStatus:
                            <>
                                {row.paymentStatus === 'ATTEND_LESSON' &&
                                    <><BookOutlined style={{color: '#04879C'}}/> Посещал занятие</>
                                }
                                {row.paymentStatus === 'WATCH_VIDEO' &&
                                    <><VideoCameraOutlined style={{color: '#F30A49'}}/> Смотрел видео</>
                                }
                                {row.paymentStatus === 'OWN_PRICE' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">1</span>
                                        </div>
                                        Занимался по особой цене
                                    </>
                                }
                                {row.paymentStatus === 'OWN_PRICE2' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">2</span>
                                        </div>
                                        Занимался по особой цене 2
                                    </>                                }
                                {row.paymentStatus === 'OWN_PRICE3' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">3</span>
                                        </div>
                                        Занимался по особой цене 3
                                    </>                                }
                                {row.paymentStatus === 'OWN_PRICE4' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">4</span>
                                        </div>
                                        Занимался по особой цене 4
                                    </>
                                }
                            </>,
                        cost: `${row.cost}₽`,
                        balanceLesson: `${row.balanceLesson}₽`,
                        bonusBalanceLesson: `${row.bonusBalanceLesson}₽ бонусов`,
                    }))
                );
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <Divider>История посещений ({groupName})</Divider>
            <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет занятий'/>}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={lessons}
                        pagination={false}
                        bordered
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                )}
            </ConfigProvider>
        </div>
    )
}
