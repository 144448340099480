import {Api} from "./index";

export const create = async (dateFrom, amount, userId, groupId, token) => {
    const {data} = await Api.post(`percent`, {
        "dateFrom": dateFrom,
        "amount": amount,
        "userId": userId,
        "groupId": groupId
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return data
}