import React, { useState, useEffect } from 'react'
import Footer from '../components/footerComponent/footer'
import Signup from '../components/signupComponent/signup'
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../utils';
import { isTokenValid } from '../utils/authUtils';

export default function RegisterPage() {

  const navigate = useNavigate();
  let [isAuth, setAuth] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token === null || !isTokenValid(token)) {
      localStorage.removeItem("token");
      setAuth(false);
    } else {
      setAuth(true);
      navigate(HOME_ROUTE);
    }
  }, [navigate]);

  return (
    !isAuth
      ?
      <Layout>
        <Signup />
        <Footer />
      </Layout>
      :
      <p />
  );
};
