import React, {useState, useEffect} from 'react'
import {getById} from "../../http/UserApi";
import {getForMove} from "../../http/GroupApi";
import {move, addPayment, addBonuses} from "../../http/StudentGroupApi";
import {getTransactionsPage} from "../../http/TransactionsApi";
import {
    Button,
    Spin,
    Divider,
    ConfigProvider,
    Table,
    Select,
    Form,
    InputNumber,
    Empty,
    QRCode,
    Popover,
    Alert, DatePicker
} from 'antd';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import locale from "antd/es/locale/ru_RU";

export default function MyStudent() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [datePayment, setDatePayment] = useState(null);
    const [dateBonusPayment, setDateBonusPayment] = useState(null);
    const [name, setName] = useState('');
    const [sumPayment, setSumPayment] = useState(0);
    const [sumBonusPayment, setSumBonusPayment] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});

    const [info, setInfo] = useState([]);
    const [transactions, setTransactions] = useState([]);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertBonusVisible, setAlertBonusVisible] = useState(false);
    const [alertMoveInfoVisible, setAlertMoveInfoVisible] = useState(false);

    const handleClose = () => {
        setAlertVisible(false);
    };

    const handleBonusClose = () => {
        setAlertBonusVisible(false);
    };

    const handleMoveInfoClose = () => {
        setAlertMoveInfoVisible(false);
    };

    const columns = [
        {
            title: 'Графа',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
        },
    ];
    const columnsTransactions = [
        {
            title: 'Дата перевода',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
        },
        {
            title: 'Сумма перевода',
            dataIndex: 'sumTransaction',
            key: 'sumTransaction',
        },
        {
            title: 'Сумма бонусов',
            dataIndex: 'bonusSumTransaction',
            key: 'bonusSumTransaction',
        },
        {
            title: 'Тип оплаты',
            dataIndex: 'type',
            key: 'type',
        },
    ];

    const params = useParams();
    const [options, setOptions] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loadingSelect, setLoadingSelect] = useState(true);
    const [loadingTransactions, setLoadingTransactions] = useState(true);

    const dateFormat = 'DD.MM.YYYY';
    const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    function getTypeText(type, order_id) {
        switch (type) {
            case 'MANUALLY':
                return 'Вручную';
            case 'ORDER':
                return `Через карту (заказ №${order_id})`;
            default:
                return 'Неизвестный тип';
        }
    }

    const getStudentInfo = async () => {
        const responseInfo = [];
        await getById(localStorage.getItem('token'), params.id,).then(
            response => {
                responseInfo.push({
                    key: 'Номер аккаунта',
                    value: response.data.id,
                });
                responseInfo.push({
                    key: 'Имя пользователя',
                    value: response.data.username,
                });
                responseInfo.push({
                    key: 'ФИО',
                    value: response.data.name,
                });
                responseInfo.push({
                    key: 'Ссылка на ВК',
                    value: <Popover overlayInnerStyle={{padding: 0}}
                                    content={<QRCode value={response.data.linkVk} bordered={false}/>}>
                        <Link
                            to={(response.data.linkVk.startsWith('http://') || response.data.linkVk.startsWith('https://')) ? response.data.linkVk : `https://${response.data.linkVk}`}
                            target='_blank' className="custom-link">{response.data.linkVk}</Link></Popover>,
                });
                responseInfo.push({
                    key: 'Номер телефона',
                    value: response.data.phoneStudent,
                });
                responseInfo.push({
                    key: 'Город проживания',
                    value: response.data.city,
                });
                responseInfo.push({
                    key: 'День рождения',
                    value: dayjs(response.data.birthday).format(dateFormat),
                });
                setInfo(responseInfo);
                setName(response.data.name);
                setLoading(false);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const getGroups = async () => {
        const optionsUpdate = [];
        await getForMove(localStorage.getItem('token'), params.groupId, params.id).then(
            response => {
                response.data.forEach(group => {
                    optionsUpdate.push({
                        label: group.name,
                        value: group.id.toString(),
                    });
                });
                setOptions(optionsUpdate);
                setLoadingSelect(false);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        getTransactions();
    }, [pagination.current, pagination.pageSize]);

    useEffect(() => {
        getTransactions();
    }, []);
    const getTransactions = async () => {
        const transactionsUpdate = [];
        await getTransactionsPage(localStorage.getItem('token'), params.groupId, params.id, pagination.current - 1, pagination.pageSize).then(
            response => {
                response.data.content.forEach(transaction => {
                    transactionsUpdate.push({
                        key: transaction.id,
                        date: dayjs(transaction.date).format(dateTimeFormat),
                        sumTransaction: `${transaction.sumTransaction}₽`,
                        bonusSumTransaction: `${transaction.bonusSumTransaction}₽ бонусов`,
                        type: getTypeText(transaction.type, transaction.order !== null ? transaction.order.id : null)
                    });
                });
                setTransactions(transactionsUpdate);
                setPagination({...pagination, total: response.data.totalElements});
            }).catch(
            error => {
                console.log(error);
            }
        );
        setLoadingTransactions(false);

    };

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const moveStudent = async () => {
        if (selectedGroup === '') {
            setAlertMoveInfoVisible(true);
        } else {
            await move(localStorage.getItem('token'), params.id, params.groupId, selectedGroup).then(
                response => {
                    goBack();
                }).catch(
                error => {
                    console.log(error);
                }
            );
        }
    };

    useEffect(() => {
        getStudentInfo();
        getGroups();
        getTransactions();
    }, []);

    const onFinishPayment = async (values) => {
        await addPayment(params.groupId, params.id, sumPayment, datePayment, localStorage.getItem('token')).then(
            response => {
                setAlertVisible(true);
                getTransactions();
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const onFinishBonusPayment = async (values) => {
        await addBonuses(params.groupId, params.id, sumBonusPayment, dateBonusPayment, localStorage.getItem('token')).then(
            response => {
                setAlertBonusVisible(true);
                getTransactions();
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    return (
        <div
            style={{
                minHeight: '100vh',
                margin: '1vw',
            }}
        >
            <Button onClick={goBack} style={{}}><LeftOutlined/> Назад</Button>
            <Divider>{name}</Divider>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет информации'/>}>
                    <Table
                        columns={columns}
                        dataSource={info}
                        pagination={false}
                        bordered
                        scroll={{x: "max-content"}}
                        style={{
                            width: '100%',
                        }}
                    />
                </ConfigProvider>
            )}
            <Divider>История оплат</Divider>
            {loadingTransactions ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет оплат'/>}>
                    <Table
                        columns={columnsTransactions
                        }
                        dataSource={transactions}
                        pagination={pagination}
                        bordered
                        onChange={handleTableChange}
                        scroll={{x: "max-content"}}
                        style={{
                            width: '100%',
                        }}
                    />
                </ConfigProvider>
            )}
            <Divider>Добавление оплаты</Divider>
            <Form
                onFinish={onFinishPayment}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                style={{width: '100%'}}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            name="datePayment"
                            format={dateFormat}
                            onChange={event => {
                                setDatePayment(event.endOf('day'))
                            }}
                            style={{
                                width: '100%',
                                marginRight: '1vw'
                            }}
                        />
                    </ConfigProvider>
                    <Form.Item
                        name="payment"
                        initialValue={sumPayment}
                        style={{
                            width: '100%',
                            margin: 0,
                            marginRight: '1vw'
                        }}
                    >
                        <InputNumber
                            onChange={event => {
                                setSumPayment(event)
                            }}
                            min={-2147483647}
                            max={2147483647}
                            suffix="₽"
                            placeholder="Сумма добавления"
                            style={{
                                width: '100%',
                            }}/>
                    </Form.Item>
                    <Button htmlType="submit">
                        Добавить
                    </Button>
                </div>
                {alertVisible && (
                    <Alert message="Успешно добавлены" type="success" closable
                           afterClose={handleClose}
                           style={{marginTop: '1vw',}}/>
                )}
            </Form>
            <Divider>Добавление бонусов</Divider>
            <Form
                onFinish={onFinishBonusPayment}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                style={{width: '100%'}}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            name="dateBonusPayment"
                            format={dateFormat}
                            onChange={event => {
                                setDateBonusPayment(event.endOf('day'))
                            }}
                            style={{
                                width: '100%',
                                marginRight: '1vw'
                            }}
                        />
                    </ConfigProvider>
                    <Form.Item
                        name="bonusPayment"
                        initialValue={sumBonusPayment}
                        style={{
                            width: '100%',
                            margin: 0,
                            marginRight: '1vw'
                        }}
                    >
                        <InputNumber
                            onChange={event => {
                                setSumBonusPayment(event)
                            }}
                            min={-2147483647}
                            max={2147483647}
                            suffix="₽ бонусов"
                            placeholder="Сумма добавления"
                            style={{
                                width: '100%',
                            }}/>
                    </Form.Item>
                    <Button htmlType="submit">
                        Добавить
                    </Button>
                </div>
                {alertBonusVisible && (
                    <Alert message="Успешно добавлены" type="success" closable
                           afterClose={handleBonusClose}
                           style={{marginTop: '1vw',}}/>
                )}
            </Form>
            <Divider>Перевод в другую группу</Divider>
            {loadingSelect ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                  description='Нет доступных групп'/>}>
                            <Select
                                allowClear
                                style={{width: '100%', marginRight: '1vw'}}
                                placeholder="Выберите группу"
                                defaultValue={[]}
                                optionFilterProp="label"
                                onChange={event => {
                                    setSelectedGroup(event)
                                }}
                                options={options}
                            />
                        </ConfigProvider>
                        <Button onClick={() => moveStudent()}>Перевести</Button>
                    </div>
                    {alertMoveInfoVisible && (
                        <Alert message="Выберите группу" type="info" closable
                               afterClose={handleMoveInfoClose}
                               style={{marginTop: '1vw',}}/>
                    )}
                </>
            )}
        </div>
    )
}