import {
    Collapse,
    ConfigProvider,
    DatePicker,
    Divider,
    Empty,
    Form,
    Row,
    Spin,
    Table
} from "antd";
import React, {useEffect, useState} from "react";
import locale from "antd/es/locale/ru_RU";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import {wages} from "../../http/WagesApi";

export default function Wages() {

    const [month, setMonth] = useState(dayjs());
    const [loading, setLoading] = useState(true);
    const [wagesList, setWagesList] = useState([]);

    const columns = [
        {
            title: 'Группа',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: 'Поступления',
            dataIndex: 'receipts',
            key: 'receipts',
        },
        {
            title: 'Списания',
            dataIndex: 'debits',
            key: 'debits',
        },
        {
            title: 'Процент',
            dataIndex: 'percent',
            key: 'percent',
        },
        {
            title: 'Зарплата',
            dataIndex: 'wages',
            key: 'wages',
        },
        {
            title: 'Премия',
            dataIndex: 'premiums',
            key: 'premiums',
        },
        {
            title: 'ЗП + премия',
            dataIndex: 'wagesWithPremiums',
            key: 'wagesWithPremiums',
        },
    ];

    const dateFormat = 'MM.YYYY';
    const dateTimeFormat = 'DD.MM.YYYY';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    const getMonthName = (date) => {
        if (date) {
            return date.format('MMMM').toUpperCase();
        }
        return '';
    };

    const getLastRowClassName = (record, index) => {
        if (index === wagesList[0].oneWagesWeek.length - 1) {
            return 'last-row';
        }
        return '';
    };

    const getWages = async (values) => {
        await wages(getMonthName(month), month.year(), localStorage.getItem('token')).then(
            response => {
                const wagesWeeks = []
                response.wagesWeeks.forEach(wagesWeek => {
                    const oneWagesWeek = []
                    wagesWeek.wagesGroups.forEach(wagesGroup => {
                            oneWagesWeek.push(
                                {
                                    groupName: wagesGroup.groupName,
                                    receipts: wagesGroup.receipts,
                                    debits: wagesGroup.debits,
                                    percent: wagesGroup.percent,
                                    wages: wagesGroup.wages,
                                    premiums: wagesGroup.premiums,
                                    wagesWithPremiums: wagesGroup.wagesWithPremiums
                                }
                            )
                        }
                    )
                    oneWagesWeek.push(
                        {
                            groupName: 'Итого',
                            receipts: wagesWeek.receiptsSum,
                            debits: wagesWeek.debitsSum,
                            percent: "-",
                            wages: wagesWeek.wagesSum,
                            premiums: wagesWeek.premiumsSum,
                            wagesWithPremiums: wagesWeek.wagesWithPremiumsSum
                        }
                    )
                    wagesWeeks.push(
                        {
                            oneWagesWeek: oneWagesWeek,
                            dateFrom: wagesWeek.dateFrom,
                            dateTo: wagesWeek.dateTo
                        }
                    );
                });
                wagesWeeks.push(
                    {
                        oneWagesWeek: [
                            {
                                groupName: 'Итого',
                                receipts: response.receiptsSum,
                                debits: response.debitsSum,
                                percent: "-",
                                wages: response.wagesSum,
                                premiums: response.premiumsSum,
                                wagesWithPremiums: response.wagesWithPremiumsSum
                            }
                        ],
                        dateFrom: response.dateFrom,
                        dateTo: response.dateTo
                    }
                );
                setWagesList(wagesWeeks)
                setLoading(false)
            }
        ).catch(
            error => {
                console.log(error)
            }
        )
    };

    useEffect(() => {
        getWages()
    }, [month]);

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Divider>Зарплата</Divider>
            <Row justify="center" align="middle">
                <Form
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    layout="horizontal"
                    initialValues={{month}}
                >
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            inputReadOnly
                            format={dateFormat}
                            value={month}
                            onChange={
                                date => setMonth(date)}
                            picker="month"
                        />
                    </ConfigProvider>
                </Form>
            </Row>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <div>
                    {wagesList.map((wage, index) => (
                        <Collapse
                            style={{margin: '1vw'}}
                            collapsible="header"
                            items={[
                                {
                                    key: {index},
                                    label: index !== wagesList.length-1? `${index + 1} неделя (${dayjs(wage.dateFrom).format(dateTimeFormat)} - ${dayjs(wage.dateTo).format(dateTimeFormat)})`:`Месяц (${dayjs(wage.dateFrom).format(dateTimeFormat)} - ${dayjs(wage.dateTo).format(dateTimeFormat)})`,
                                    children:
                                        <ConfigProvider
                                            key={index}
                                            renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                      description='Нет данных'/>}
                                        >
                                            <Table
                                                columns={columns}
                                                dataSource={wage.oneWagesWeek}
                                                bordered
                                                pagination={false}
                                                scroll={{x: "max-content"}}
                                                rowClassName={getLastRowClassName}
                                            />
                                        </ConfigProvider>,
                                },
                            ]}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}