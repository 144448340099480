import {Api} from "./index";

export const wages = async (month, year, token) => {
    const {data} = await Api.get(`wages`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "month": month,
            "year": year
        }
    })
    return data
}

export const analytics = async (month, year, token) => {
    const {data} = await Api.get(`wages/analytics`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "month": month,
            "year": year
        }
    })
    return data
}