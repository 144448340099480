import {useNavigate} from "react-router-dom";
import {Alert, Button, Card, Divider, Form, Input, Spin} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {addTeacher, checkUsername, registration} from "../../http/UserApi";

export default function AddTeacher() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [name, setName] = useState("");
    const [phoneTeacher, setPhoneTeacher] = useState("");
    const [linkVk, setLinkVk] = useState("");

    const [adding, setAdding] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);

    const handleClose = () => {
        setAlertVisible(false);
    };

    const checkUsernameAvailability = async (rule, value) => {
        try {
            const response = await checkUsername(value);
            if (response.data) {
                return Promise.resolve();
            } else {
                return Promise.reject('Имя пользователя уже занято');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const checkPasswords = async (rule, value) => {
        try {
            if (password === password2) {
                return Promise.resolve();
            } else {
                return Promise.reject('Пароли не совпадают');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const onFinish = async (values) => {
        setAdding(true);
        await addTeacher(localStorage.getItem('token'), username, password, name, phoneTeacher, linkVk).then(
            response => {
                navigate('/home')
            }).catch(
            error => {
                setAlertVisible(true);
                setAdding(false);
                console.log(error)
            }
        )
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div>
                    <Divider>Добавление преподавателя</Divider>
                    <Card
                        style={{display: "inline-block",}}
                    >
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{span: 11}}
                            wrapperCol={{span: 13}}
                            layout="horizontal"
                        >
                            <Form.Item
                                name="name"
                                label="ФИО"
                                initialValue={name}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите ФИО',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setName(event.target.value)
                                    }}
                                    onBlur={name.handleBlur}
                                    name="name"
                                    type="text"
                                    placeholder="ФИО"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="username"
                                label="Логин"
                                initialValue={username}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите логин',
                                    },
                                    {
                                        validator: checkUsernameAvailability,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setUsername(event.target.value)
                                    }}
                                    onBlur={username.handleBlur}
                                    name="username"
                                    type="text"
                                    placeholder="Логин"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Пароль"
                                initialValue={password}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите пароль',
                                    },
                                ]}
                            >
                                <Input.Password
                                    onChange={event => {
                                        setPassword(event.target.value)
                                    }}
                                    onBlur={password.handleBlur}
                                    name="password"
                                    type="password"
                                    placeholder="Пароль"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password2"
                                label="Подтверждение пароля"
                                initialValue={password}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите пароль',
                                    },
                                    {
                                        validator: checkPasswords,
                                    },
                                ]}
                            >
                                <Input.Password
                                    onChange={event => {
                                        setPassword2(event.target.value)
                                    }}
                                    name="password2"
                                    type="password"
                                    placeholder="Подтверждение пароля"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="linkVk"
                                label="Ссылка на ВК"
                                initialValue={linkVk}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите ссылку на ВК',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setLinkVk(event.target.value)
                                    }}
                                    onBlur={linkVk.handleBlur}
                                    name="linkVk"
                                    type="text"
                                    placeholder="https://vk.com/moroz_zov"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="phoneStudent"
                                label="Номер телефона"
                                initialValue={phoneTeacher}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите номер телефона',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setPhoneTeacher(event.target.value)
                                    }}
                                    onBlur={phoneTeacher.handleBlur}
                                    name="phoneStudent"
                                    type="text"
                                    placeholder="Номер телефона"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            {adding ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                    }}>
                                    Создать
                                </Button>
                            )}
                        </Form>
                        {alertVisible && (
                            <Alert message="Ошибка создания" type="error" closable
                                   afterClose={handleClose}
                                   style={{marginTop: '1vw',}}/>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    )
}