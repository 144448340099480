import {Api} from "./index";

export const getAll = async (token, page, size, sort) => {
    return await Api.get(`group`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "page": page,
            "size": size,
            "sort": sort
        }
    })
}

export const getAllByStudent = async (token, isActive, isActiveStudent) => {
    return await Api.get(`student-group/student`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "is_active": isActive,
            "is_active_student": isActiveStudent
        }
    })
}

export const getAllByCurrentTeacher = async (token, isActive) => {
    return await Api.get(`group/teacher`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "is_active": isActive
        }
    })
}

export const getAllByTeacher = async (token, id, isActive) => {
    return await Api.get(`group/teacher`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "is_active": isActive,
            "id": id
        }
    })
}

export const getById = async (token, id) => {
    return await Api.get(`group/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getNameById = async (token, id) => {
    return await Api.get(`group/name/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getForMove = async (token, groupId, studentId) => {
    return await Api.get(`group/${groupId}/${studentId}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const update = async (name, technology, schedule, token, id) => {
    const {data} = await Api.put(`group/${id}`, {"name": name, "technology": technology, "schedule": schedule}, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return data
}

export const createActive = async (name, technology, schedule, token) => {
    const {data} = await Api.post(`group`, {
        "name": name,
        "technology": technology,
        "schedule": schedule,
        "isActive": true
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return data
}

export const graduate = async (token, id) => {
    return await Api.delete(`group/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const restore = async (token, id) => {
    return await Api.get(`group/restore/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}