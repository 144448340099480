import React, {useState, useEffect} from 'react'
import Footer from '../components/footerComponent/footer'
import Home from '../components/homeComponent/homeComponent'
import Header from '../components/headerComponent/header'
import {Layout} from 'antd';
import {useNavigate} from 'react-router-dom';
import {SIGNIN_ROUTE, TEACHERS_ROUTE} from '../utils';
import {isTokenValid} from '../utils/authUtils';
import jwtDecode from "jwt-decode";

export default function HomePage() {

    const navigate = useNavigate();
    let [isAuth, setAuth] = useState(false)
    const isAdmin = 'ADMIN';

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token != null && isTokenValid(token)) {
            if (isAdmin === jwtDecode(localStorage.getItem('token')).role) {
                navigate(TEACHERS_ROUTE)
            }
            setAuth(true);
        } else {
            localStorage.removeItem("token");
            setAuth(false);
            navigate(SIGNIN_ROUTE)
        }
    }, [navigate]);

    return (
        isAuth
            ?
            <Layout>
                <Header/>
                <Home/>
                <Footer/>
            </Layout>
            :
            <p/>
    );
};