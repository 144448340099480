import {Api} from "./index";

export const create = async (date, amount, userId, groupId, token) => {
    const {data} = await Api.post(`premium`, {
        "date": date,
        "amount": amount,
        "userId": userId,
        "groupId": groupId
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return data
}