import React, {useState, useEffect} from 'react'
import {getById} from "../../http/GroupApi";
import {update} from "../../http/GroupApi";
import {Button, Spin, Divider, Form, Card, Input, Alert} from 'antd';
import {useParams, useNavigate} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';


export default function EditGroup() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [name, setName] = useState("");
    const [technology, setTechnology] = useState("");
    const [schedule, setSchedule] = useState("");

    const params = useParams();

    const [adding, setAdding] = useState(false);
    const [loading, setloading] = useState(true);

    const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
    const [alertErrorVisible, setAlertErrorVisible] = useState(false);

    const handleSuccessClose = () => {
        setAlertSuccessVisible(false);
    };

    const handleErrorClose = () => {
        setAlertErrorVisible(false);
    };

    const getInfo = async () => {
        await getById(localStorage.getItem('token'), params.id).then(
            response => {
                setloading(false);

                setName(response.data.name)
                setTechnology(response.data.technology)
                setSchedule(response.data.schedule)
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    useEffect(() => {
        getInfo();
    }, []);

    const onFinish = async (values) => {
        setAdding(true);
        await update(name, technology, schedule, localStorage.getItem('token'), params.id).then(
            response => {
                setAlertSuccessVisible(true);
                setAdding(false);
            }).catch(
            error => {
                setAlertErrorVisible(true);
                setAdding(false);
                console.log(error)
            }
        )
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }


    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{}}
                >
                    <Divider>Редактирование группы</Divider>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Spin/>
                        </div>
                    ) : (
                        <Card
                            style={{display: "inline-block",}}
                        >
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                labelCol={{span: 10}}
                                wrapperCol={{span: 14}}
                                layout="horizontal"
                            >
                                <Form.Item
                                    name="name"
                                    label="Название группы"
                                    initialValue={name}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите название группы',
                                        },
                                    ]}

                                >
                                    <Input
                                        onChange={event => {
                                            setName(event.target.value)
                                        }}
                                        type="text"
                                        placeholder="Название группы"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Технология"
                                    name="technology"
                                    initialValue={technology}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите технологию',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={event => {
                                            setTechnology(event.target.value)
                                        }}
                                        name="technology"
                                        type="text"
                                        placeholder="Технология"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="schedule"
                                    label="Расписание"
                                    initialValue={schedule}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите расписание',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={event => {
                                            setSchedule(event.target.value)
                                        }}
                                        name="schedule"
                                        type="text"
                                        placeholder="Расписание"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                {adding ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Spin/>
                                    </div>
                                ) : (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{
                                            width: '100%',
                                        }}>
                                        Сохранить
                                    </Button>
                                )}
                            </Form>
                            {alertSuccessVisible && (
                                <Alert message="Успешно сохранено" type="success" closable
                                       afterClose={handleSuccessClose}
                                       style={{marginTop: '1vw',}}/>
                            )}
                            {alertErrorVisible && (
                                <Alert message="Ошибка сохранения" type="error" closable
                                       afterClose={handleErrorClose}
                                       style={{marginTop: '1vw',}}/>
                            )}
                        </Card>
                    )}
                </div>
            </div>
        </div>
    )
}
