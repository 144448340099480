import {Api, authApi} from "./index";

export const getTransactionsPage = async (token, groupId, studentId, page, size) => {
    return await Api.get(`/transaction/${groupId}/${studentId}`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "page": page,
            "size": size
        }
    })
}

export const getAllTransactionsPageByStudent = async (token, studentId, page, size, sort) => {
    return await Api.get(`/transaction/by-student/all/${studentId}`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "page": page,
            "size": size,
            "sort": sort
        }
    })
}

export const getTransactionsPageByCurrentStudent = async (token, groupId, page, size) => {
    return await Api.get(`/transaction/by-student/${groupId}`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "page": page,
            "size": size
        }
    })
}