import './App.css';
import AppRouter from "./AppRouter";
import { BrowserRouter as Router} from "react-router-dom";
import './custom-style.css';

const App = () =>{
  return (
    <Router>
      <AppRouter/>
    </Router>
  );
}

export default App;
