import React, {useState, useEffect} from 'react'
import {getAllByStudent, getAllByCurrentTeacher, graduate, restore} from "../../http/GroupApi";
import {Button, Table, Spin, Divider, ConfigProvider, Empty, Alert} from 'antd';
import {
    MY_STUDENTS_ROUTE,
    EDIT_GROUP_ROUTE,
    ADD_GROUP_ROUTE,
    HISTORY_ROUTE,
    LESSONS_ROUTE,
    PAYMENTS_ROUTE,
    PAY_ROUTE, HOMEWORKS_ROUTE
} from "../../utils";
import {Link, useNavigate} from 'react-router-dom';
import jwtDecode from 'jwt-decode';

export default function Home() {

    const studentColumns = [
        {
            title: 'Название группы',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Технология',
            dataIndex: 'technology',
            key: 'technology',
        },
        {
            title: 'Расписание',
            dataIndex: 'schedule',
            key: 'schedule',
        },
        {
            title: 'Баланс',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Бонусный баланс',
            dataIndex: 'bonusBalance',
            key: 'bonusBalance',
        },
        {
            title: 'Пополнение баланса',
            dataIndex: 'pay',
            key: 'pay',
            align: 'center',
        },
        {
            title: 'История посещений',
            dataIndex: 'history',
            key: 'history',
            align: 'center',
        },
        {
            title: 'История оплат',
            dataIndex: 'payments',
            key: 'payments',
            align: 'center',
        },
    ];

    const teacherColumns = [
        {
            title: 'Название группы',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Технология',
            dataIndex: 'technology',
            key: 'technology',
        },
        {
            title: 'Расписание',
            dataIndex: 'schedule',
            key: 'schedule',
        },
        {
            title: 'Занятия и оплаты',
            dataIndex: 'lessons',
            key: 'lessons',
            align: 'center',
        },
        {
            title: 'Домашние задания',
            dataIndex: 'homeworks',
            key: 'homeworks',
            align: 'center',
        },
        {
            title: 'Редактировать группу',
            dataIndex: 'editGroup',
            key: 'editGroup',
            align: 'center',
        },
        {
            title: 'Выпустить группу',
            dataIndex: 'graduate',
            key: 'graduate',
            align: 'center',
        },
    ];

    const teacherInactiveColumns = [
        {
            title: 'Название группы',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Технология',
            dataIndex: 'technology',
            key: 'technology',
        },
        {
            title: 'Расписание',
            dataIndex: 'schedule',
            key: 'schedule',
        },
        {
            title: 'Занятия и оплаты',
            dataIndex: 'lessons',
            key: 'lessons',
            align: 'center',
        },
        {
            title: 'Редактировать группу',
            dataIndex: 'editGroup',
            key: 'editGroup',
            align: 'center',
        },
        {
            title: 'Восстановить группу',
            dataIndex: 'restore',
            key: 'restore',
            align: 'center',
        },
    ];

    const navigate = useNavigate();

    const [studentBannedGroups, setStudentBannedGroups] = useState([]);
    const [studentInactiveGroups, setStudentInactiveGroups] = useState([]);
    const [studentGroups, setStudentGroups] = useState([]);
    const [teacherGroups, setTeacherGroups] = useState([]);
    const [teacherInactiveGroups, setTeacherInactiveGroups] = useState([]);
    const [loading, setloading] = useState(true);
    const [loadingInactive, setLoadingInactive] = useState(true);
    const [loadingBanned, setLoadingBanned] = useState(true);

    const isTeacher = 'TEACHER'
    const isStudent = 'STUDENT'

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertRestoreVisible, setAlertRestoreVisible] = useState(false);

    const handleClose = () => {
        setAlertVisible(false);
    };

    const handleRestoreClose = () => {
        setAlertRestoreVisible(false);
    };

    useEffect(() => {
        if (isStudent === jwtDecode(localStorage.getItem('token')).role) {
            getStudentGroups();
            getStudentInactiveGroups();
            getStudentBannedGroups();
        } else if (isTeacher === jwtDecode(localStorage.getItem('token')).role) {
            getTeacherGroups();
            getTeacherInactiveGroups();
        }
    }, []);

    const graduateGroup = async (id) => {
        await graduate(localStorage.getItem('token'), id).then(
            response => {
                getTeacherGroups();
                getTeacherInactiveGroups();
                setAlertVisible(true);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const restoreGroup = async (id) => {
        await restore(localStorage.getItem('token'), id).then(
            response => {
                getTeacherGroups();
                getTeacherInactiveGroups();
                setAlertRestoreVisible(true);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getStudentGroups = async () => {
        await getAllByStudent(localStorage.getItem('token'), true, true).then(
            response => {
                setloading(false);
                setStudentGroups(
                    response.data.map(row => ({
                        key: row.id,
                        name: row.group.name,
                        technology: row.group.technology,
                        schedule: row.group.schedule,
                        balance: `${row.balance}₽`,
                        bonusBalance: `${row.bonusBalance}₽ бонусов`,
                        pay: <Link to={`${PAY_ROUTE}/${row.group.id}`} className="custom-link">Пополнить</Link>,
                        history: <Link to={`${HISTORY_ROUTE}/${row.group.id}`}
                                       className="custom-link">Посещения</Link>,
                        payments: <Link to={`${PAYMENTS_ROUTE}/${row.group.id}`} className="custom-link">Оплаты</Link>,
                    }))
                );
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getStudentInactiveGroups = async () => {
        await getAllByStudent(localStorage.getItem('token'), false, true).then(
            response => {
                setLoadingInactive(false);
                setStudentInactiveGroups(
                    response.data.map(row => ({
                        key: row.id,
                        name: row.group.name,
                        technology: row.group.technology,
                        schedule: row.group.schedule,
                        balance: `${row.balance}₽`,
                        bonusBalance: `${row.bonusBalance}₽ бонусов`,
                        pay: <Link to={`${PAY_ROUTE}/${row.group.id}`} className="custom-link">Пополнить</Link>,
                        history: <Link to={`${HISTORY_ROUTE}/${row.group.id}`}
                                       className="custom-link">Посещения</Link>,
                        payments: <Link to={`${PAYMENTS_ROUTE}/${row.group.id}`} className="custom-link">Оплаты</Link>,
                    }))
                );
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getStudentBannedGroups = async () => {
        await getAllByStudent(localStorage.getItem('token'), false, false).then(
            response => {
                setLoadingBanned(false);
                setStudentBannedGroups(
                    response.data.map(row => ({
                        key: row.id,
                        name: row.group.name,
                        technology: row.group.technology,
                        schedule: row.group.schedule,
                        balance: `${row.balance}₽`,
                        bonusBalance: `${row.bonusBalance}₽ бонусов`,
                        pay: <Link to={`${PAY_ROUTE}/${row.group.id}`} className="custom-link">Пополнить</Link>,
                        history: <Link to={`${HISTORY_ROUTE}/${row.group.id}`}
                                       className="custom-link">Посещения</Link>,
                        payments: <Link to={`${PAYMENTS_ROUTE}/${row.group.id}`} className="custom-link">Оплаты</Link>,
                    }))
                );
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getTeacherGroups = async () => {
        await getAllByCurrentTeacher(localStorage.getItem('token'), true).then(
            response => {
                setloading(false);
                setTeacherGroups(
                    response.data.map(row => ({
                        key: row.id,
                        name: <Link to={`${MY_STUDENTS_ROUTE}/${row.id}`}
                                    className="custom-link">{row.name} ({row.studentsCount} чел.)</Link>,
                        technology: row.technology,
                        schedule: row.schedule,
                        lessons: <Link to={`${LESSONS_ROUTE}/${row.id}`} className="custom-link">Занятия</Link>,
                        homeworks: <Link to={`${HOMEWORKS_ROUTE}/${row.id}`} className="custom-link">Домашние
                            задания</Link>,
                        editGroup: <Button
                            onClick={() => navigate(`${EDIT_GROUP_ROUTE}/${row.id}`)}>Редактировать</Button>,
                        graduate: <Button type="primary" danger
                                          onClick={() => graduateGroup(row.id)}>Выпустить</Button>
                    }))
                );
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getTeacherInactiveGroups = async () => {
        await getAllByCurrentTeacher(localStorage.getItem('token'), false).then(
            response => {
                setLoadingInactive(false);
                setTeacherInactiveGroups(
                    response.data.map(row => ({
                        key: row.id,
                        name: <Link to={`${MY_STUDENTS_ROUTE}/${row.id}`}
                                    className="custom-link">{row.name} ({row.studentsCount} чел.)</Link>,
                        technology: row.technology,
                        schedule: row.schedule,
                        lessons: <Link to={`${LESSONS_ROUTE}/${row.id}`} className="custom-link">Занятия</Link>,
                        editGroup: <Button
                            onClick={() => navigate(`${EDIT_GROUP_ROUTE}/${row.id}`)}>Редактировать</Button>,
                        restore: <Button
                            onClick={() => restoreGroup(row.id)}>Восстановить</Button>
                    }))
                );
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Divider>Активные группы</Divider>
            <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет групп'/>}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <>
                        {isStudent === jwtDecode(localStorage.getItem('token')).role &&
                            <Table
                                columns={studentColumns}
                                dataSource={studentGroups}
                                pagination={false}
                                bordered
                                scroll={{x: "max-content"}}
                                style={{
                                    margin: '1vw',
                                }}
                            />
                        }
                        {isTeacher === jwtDecode(localStorage.getItem('token')).role &&
                            <Table
                                columns={teacherColumns}
                                dataSource={teacherGroups}
                                pagination={false}
                                bordered
                                scroll={{x: "max-content"}}
                                style={{
                                    margin: '1vw',
                                }}
                                footer={() => <Button onClick={() => navigate(ADD_GROUP_ROUTE)} style={{
                                    margin: '0 auto',
                                    display: 'block'
                                }}>Создать группу</Button>}
                            />
                        }
                    </>
                )}
                {alertVisible && (
                    <Alert message="Группа успешно выпущена" type="success" closable afterClose={handleClose}
                           style={{margin: '1vw',}}/>
                )}
            </ConfigProvider>
            <Divider>Выпустившиеся группы</Divider>
            <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет групп'/>}>
                {loadingInactive ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <>
                        {isStudent === jwtDecode(localStorage.getItem('token')).role &&
                            <Table
                                columns={studentColumns}
                                dataSource={studentInactiveGroups}
                                pagination={false}
                                bordered
                                scroll={{x: "max-content"}}
                                style={{
                                    margin: '1vw',
                                }}
                            />
                        }
                        {isTeacher === jwtDecode(localStorage.getItem('token')).role &&
                            <Table
                                columns={teacherInactiveColumns}
                                dataSource={teacherInactiveGroups}
                                pagination={false}
                                bordered
                                scroll={{x: "max-content"}}
                                style={{
                                    margin: '1vw',
                                }}
                            />
                        }
                    </>
                )}

                {alertRestoreVisible && (
                    <Alert message="Группа успешно восстановлена" type="success" closable
                           afterClose={handleRestoreClose}
                           style={{margin: '1vw',}}/>
                )}
            </ConfigProvider>
            {isStudent === jwtDecode(localStorage.getItem('token')).role && <>
                <Divider>Исключенные группы</Divider>
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет групп'/>}>
                    {loadingBanned ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Spin/>
                        </div>
                    ) : (
                        <Table
                            columns={studentColumns}
                            dataSource={studentBannedGroups}
                            pagination={false}
                            bordered
                            scroll={{x: "max-content"}}
                            style={{
                                margin: '1vw',
                            }}
                        />
                    )}
                </ConfigProvider>
            </>}
        </div>
    )
}
