import React, {useState} from 'react'

import {Layout, Button, Menu} from 'antd';
import '../headerComponent/header.css'
import logo from '../headerComponent/logo-white.png'
import {
    ADD_PERCENT_ROUTE,
    ADD_PREMIUM_ROUTE, ANALYTICS_ROUTE,
    BIRTHDAYS_ROUTE,
    GROUPS_ROUTE,
    HOME_ROUTE,
    SETTINGS_ROUTE,
    STUDENTS_ROUTE,
    TEACHERS_ROUTE,
    WAGES_ROUTE
} from "../../utils";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import jwtDecode from "jwt-decode";

export default function Header() {
    const location = useLocation();
    const {Header} = Layout;

    const navigate = useNavigate();

    const isAdmin = 'ADMIN';
    const isTeacher = 'TEACHER';
    const isStudent = 'STUDENT';

    const logout = () => {
        localStorage.removeItem("token");
        navigate('/signin');
    };

    const itemsStudent = [
        {
            label: (
                <Link to={HOME_ROUTE}>Мои группы</Link>
            ),
            key: '/home',
        },
        {
            label: (
                <Link to={SETTINGS_ROUTE}>Персональные данные</Link>
            ),
            key: '/settings',
        },
    ];

    const itemsTeacher = [
        {
            label: (
                <Link to={HOME_ROUTE}>Мои группы</Link>
            ),
            key: '/home',
        },
        {
            label: (
                <Link to={WAGES_ROUTE}>Зарплата</Link>
            ),
            key: '/wages',
        },
        {
            label: (
                <Link to={SETTINGS_ROUTE}>Персональные данные</Link>
            ),
            key: '/settings',
        },
    ];

    const itemsAdmin = [
        {
            label: 'Финансы',
            key: 'SubMenuFinance',
            children: [
                {
                    label: (
                        <Link to={ANALYTICS_ROUTE}>Аналитика</Link>
                    ),
                    key: '/analytics',
                },
                {
                    label: (
                        <Link to={ADD_PREMIUM_ROUTE}>Начисление премии</Link>
                    ),
                    key: '/addpremium',
                },
                {
                    label: (
                        <Link to={ADD_PERCENT_ROUTE}>Установка процента</Link>
                    ),
                    key: '/addpercent',
                },
            ],
        },
        {
            label: 'Структура',
            key: 'SubMenuStructure',
            children: [
                {
                    label: (
                        <Link to={TEACHERS_ROUTE}>Преподаватели</Link>
                    ),
                    key: '/teachers',
                },
                {
                    label: (
                        <Link to={STUDENTS_ROUTE}>Ученики</Link>
                    ),
                    key: '/students',
                },
                {
                    label: (
                        <Link to={GROUPS_ROUTE}>Группы</Link>
                    ),
                    key: '/groups',
                },
                {
                    label: (
                        <Link to={BIRTHDAYS_ROUTE}>Дни рождения</Link>
                    ),
                    key: '/birthdays',
                }
            ]
        },
        {
            label: (
                <Link to={SETTINGS_ROUTE}>Персональные данные</Link>
            ),
            key: '/settings',
        },
    ];

    const [current, setCurrent] = useState(location.pathname);
    const onClick = (e) => {
        setCurrent(e.key);
    };

    return (
        <Header
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 999,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Link to={HOME_ROUTE}> <img src={logo} alt="IT-park"
                                        style={{maxHeight: '50px', width: 'auto', verticalAlign: 'middle'}}/></Link>
            {isAdmin === jwtDecode(localStorage.getItem('token')).role &&
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" theme="dark"
                      style={{maxWidth: '70%'}} items={itemsAdmin}/>
            }
            {isTeacher === jwtDecode(localStorage.getItem('token')).role &&
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" theme="dark"
                      style={{maxWidth: '70%'}} items={itemsTeacher}/>
            }
            {isStudent === jwtDecode(localStorage.getItem('token')).role &&
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" theme="dark"
                      style={{maxWidth: '70%'}} items={itemsStudent}/>
            }
            <Button onClick={logout} ghost style={{width: 'auto'}}>
                Выйти
            </Button>
        </Header>
    );
}
    ;
