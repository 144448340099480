import React from 'react'
import { Layout, Col, Row } from 'antd';

export default function Footer() {
  const { Footer } = Layout;

  return (
    <Footer
      style={{
        textAlign: 'center',
        marginTop: '10vh',
        paddingBottom: 0
      }}
    >
      <Row>
        <Col flex="auto">
          <p>ИП Смыслов Алексей Михайлович <br />ОГРНИП: 318325600036243</p>
        </Col>
        <Col flex="auto">
          <p>ИНН: 325502986267 <br />Дата присвоения ОГРНИП: 06.07.2018</p>
        </Col>
        <Col flex="auto">
          <p>itpark.team@yandex.ru <br /></p>
        </Col>
      </Row>
    </Footer>
  )
}
