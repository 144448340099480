import React, {useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom';
import {Button, Divider, Form, Card, InputNumber, Typography, Alert, Spin} from 'antd';
import {LeftOutlined} from '@ant-design/icons';
import {registerOrder} from "../../http/OrderApi";

const {Paragraph, Text} = Typography;

export default function Payment() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const params = useParams();
    const [amount, setAmount] = useState(0);

    const [adding, setAdding] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const handleClose = () => {
        setAlertVisible(false);
    };

    const onFinish = async (values) => {
        setAdding(true);
        await registerOrder(localStorage.getItem('token'), params.id, amount).then(
            response => {
                if (response && response.data && response.data) {
                    window.location.href = response.data;
                } else {
                    setAdding(false);
                    setAlertVisible(true);
                }
            }).catch(
            error => {
                setAdding(false);
                console.log(error)
            }
        )
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div>
                    <Divider>Пополнение баланса</Divider>
                    <Card
                        style={{display: "inline-block",}}
                    >
                        <Paragraph>
                            <blockquote>
                                <Text>Проверьте, что вы открыли сайт в браузере системы (Safari, Chrome, Opera и
                                    т.д)</Text>
                            </blockquote>
                        </Paragraph>
                        <Paragraph>
                            <blockquote>
                                <Text>Если деньги, не пришли на счёт сразу, они автоматически зачислятся на счёт в
                                    течение часа</Text>
                            </blockquote>
                        </Paragraph>
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="horizontal"
                        >
                            <Form.Item
                                name="sum"
                                label="Сумма пополнения"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите сумму пополнения',
                                    },
                                ]}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setAmount(event)
                                    }}
                                    min={0}
                                    max={2147483647}
                                    suffix="₽"
                                    placeholder="Сумма пополнения"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            {adding ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                    }}>
                                    Пополнить
                                </Button>
                            )}
                        </Form>
                        {alertVisible && (
                            <Alert message="Ошибка при получении ссылки" type="error" closable
                                   afterClose={handleClose}
                                   style={{marginTop: '1vw',}}/>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    )
}
