import {
    Collapse,
    ConfigProvider,
    DatePicker,
    Divider,
    Empty,
    Form,
    Row,
    Spin,
    Table
} from "antd";
import React, {useEffect, useState} from "react";
import locale from "antd/es/locale/ru_RU";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import {analytics} from "../../http/WagesApi";

export default function Analytics() {

    const [month, setMonth] = useState(dayjs());
    const [loading, setLoading] = useState(true);
    const [wagesList, setWagesList] = useState([]);

    const columns = [
        {
            title: 'Преподаватель',
            dataIndex: 'teacher',
            key: 'teacher',
        },
        {
            title: 'Поступления',
            dataIndex: 'receipts',
            key: 'receipts',
        },
        {
            title: 'Списания',
            dataIndex: 'debits',
            key: 'debits',
        },
        {
            title: 'Зарплата',
            dataIndex: 'wages',
            key: 'wages',
        },
        {
            title: 'Премия',
            dataIndex: 'premiums',
            key: 'premiums',
        },
        {
            title: 'ЗП + премия',
            dataIndex: 'wagesWithPremiums',
            key: 'wagesWithPremiums',
        },
        {
            title: 'Баланс',
            dataIndex: 'balance',
            key: 'balance',
        },
    ];

    const dateFormat = 'MM.YYYY';
    const dateTimeFormat = 'DD.MM.YYYY';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    const getMonthName = (date) => {
        if (date) {
            return date.format('MMMM').toUpperCase();
        }
        return '';
    };

    const getLastRowClassName = (record, index) => {
        if (index === wagesList[0].oneWagesWeek.length - 1) {
            return 'last-row';
        }
        return '';
    };

    const getWages = async (values) => {
        await analytics(getMonthName(month), month.year(), localStorage.getItem('token')).then(
            response => {
                const wagesWeeks = []
                let receiptsMonthSum = 0;
                let debitsMonthSum = 0;
                let wagesMonthSum = 0.0;
                let premiumsMonthSum = 0;
                let wagesWithPremiumsMonthSum = 0.0;
                for (let i = 0; i < response[0].wagesWeeks.length; i++) {
                    const oneWagesWeek = [];
                    let receiptsSum = 0;
                    let debitsSum = 0;
                    let wagesSum = 0.0;
                    let premiumsSum = 0;
                    let wagesWithPremiumsSum = 0.0;
                    for (let j = 0; j < response.length; j++) {
                        oneWagesWeek.push(
                            {
                                teacher: response[j].teacherName,
                                receipts: response[j].wagesWeeks[i].receiptsSum,
                                debits: response[j].wagesWeeks[i].debitsSum,
                                wages: response[j].wagesWeeks[i].wagesSum,
                                premiums: response[j].wagesWeeks[i].premiumsSum,
                                wagesWithPremiums: response[j].wagesWeeks[i].wagesWithPremiumsSum,
                                balance: response[j].wagesWeeks[i].receiptsSum - response[j].wagesWeeks[i].wagesWithPremiumsSum,
                            }
                        );
                        receiptsSum += response[j].wagesWeeks[i].receiptsSum;
                        debitsSum += response[j].wagesWeeks[i].debitsSum;
                        wagesSum += response[j].wagesWeeks[i].wagesSum;
                        premiumsSum += response[j].wagesWeeks[i].premiumsSum;
                        wagesWithPremiumsSum += response[j].wagesWeeks[i].wagesWithPremiumsSum;
                    }
                    oneWagesWeek.push(
                        {
                            teacher: "Итого",
                            receipts: receiptsSum,
                            debits: debitsSum,
                            wages: wagesSum,
                            premiums: premiumsSum,
                            wagesWithPremiums: wagesWithPremiumsSum,
                            balance: receiptsSum - wagesWithPremiumsSum
                        }
                    );
                    receiptsMonthSum += receiptsSum;
                    debitsMonthSum += debitsSum;
                    wagesMonthSum += wagesSum;
                    premiumsMonthSum += premiumsSum;
                    wagesWithPremiumsMonthSum += wagesWithPremiumsSum;
                    wagesWeeks.push(
                        {
                            oneWagesWeek: oneWagesWeek,
                            dateFrom: response[0].wagesWeeks[i].dateFrom,
                            dateTo: response[0].wagesWeeks[i].dateTo
                        }
                    );
                }
                wagesWeeks.push(
                    {
                        oneWagesWeek: [{
                            teacher: "Итого",
                            receipts: receiptsMonthSum,
                            debits: debitsMonthSum,
                            wages: wagesMonthSum,
                            premiums: premiumsMonthSum,
                            wagesWithPremiums: wagesWithPremiumsMonthSum,
                            balance: receiptsMonthSum - wagesWithPremiumsMonthSum
                        }],
                        dateFrom: response[0].wagesWeeks[0].dateFrom,
                        dateTo: response[0].wagesWeeks[response[0].wagesWeeks.length - 1].dateTo
                    }
                );
                setWagesList(wagesWeeks)
                setLoading(false)
            }
        ).catch(
            error => {
                console.log(error)
            }
        )
    };

    useEffect(() => {
        getWages()
    }, [month]);

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Divider>Аналитика по преподавателям</Divider>
            <Row justify="center" align="middle">
                <Form
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    layout="horizontal"
                    initialValues={{month}}
                >
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            inputReadOnly
                            format={dateFormat}
                            value={month}
                            onChange={
                                date => setMonth(date)}
                            picker="month"
                        />
                    </ConfigProvider>
                </Form>
            </Row>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <div>
                    {wagesList.map((wage, index) => (
                        <Collapse
                            style={{margin: '1vw'}}
                            collapsible="header"
                            items={[
                                {
                                    key: {index},
                                    label: index !== wagesList.length - 1 ? `${index + 1} неделя (${dayjs(wage.dateFrom).format(dateTimeFormat)} - ${dayjs(wage.dateTo).format(dateTimeFormat)})` : `Месяц (${dayjs(wage.dateFrom).format(dateTimeFormat)} - ${dayjs(wage.dateTo).format(dateTimeFormat)})`,
                                    children:
                                        <ConfigProvider
                                            key={index}
                                            renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                      description='Нет данных'/>}
                                        >
                                            <Table
                                                columns={columns}
                                                dataSource={wage.oneWagesWeek}
                                                bordered
                                                pagination={false}
                                                scroll={{x: "max-content"}}
                                                rowClassName={getLastRowClassName}
                                            />
                                        </ConfigProvider>,
                                },
                            ]}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}