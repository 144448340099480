import React, {useState} from 'react'
import dayjs from 'dayjs';
import locale from "antd/es/locale/ru_RU";
import "dayjs/locale/ru";
import updateLocale from 'dayjs/plugin/updateLocale';
import {Layout, Button, Form, Input, Card, DatePicker, ConfigProvider, Alert, Checkbox, Spin} from 'antd';
import {checkUsername} from "../../http/UserApi";
import {registration} from "../../http/UserApi";
import {Link, useNavigate} from 'react-router-dom';
import {CONFIDENTIALITY_ROUTE, SIGNIN_ROUTE} from '../../utils';

export default function Signup() {

    const {Content} = Layout;
    const navigate = useNavigate();

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    const dateFormat = 'DD.MM.YYYY';

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [name, setName] = useState("");
    const [phoneStudent, setPhoneStudent] = useState("");
    const [linkVk, setLinkVk] = useState("");
    const [city, setCity] = useState("");
    const [birthday, setBirthday] = useState();
    const [checked, setChecked] = useState(true);

    const [adding, setAdding] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const handleClose = () => {
        setAlertVisible(false);
    };

    const checkUsernameAvailability = async (rule, value) => {
        try {
            const response = await checkUsername(value);
            if (response.data) {
                return Promise.resolve();
            } else {
                return Promise.reject('Имя пользователя уже занято');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const checkPasswords = async (rule, value) => {
        try {
            if (password === password2) {
                return Promise.resolve();
            } else {
                return Promise.reject('Пароли не совпадают');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const checkCheckBox = async (rule, value) => {
        if (checked) {
            return Promise.resolve();
        } else {
            return Promise.reject('Ознакомьтесь с политикой конфиденциальности');
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    const onFinish = async (values) => {
        setAdding(true);
        await registration(username, password, name, phoneStudent, linkVk, city, birthday).then(
            response => {
                let token = response.data
                navigate('/home')
            }).catch(
            error => {
                setAlertVisible(true);
                setAdding(false);
                console.log(error)
            }
        )
    };

    return (
        <Content
            className="site-layout"
            style={{
                padding: 0,
            }}
        >
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Card
                    title="Регистрация"
                    style={{display: "inline-block", margin: "auto"}}
                >
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        labelCol={{span: 11}}
                        wrapperCol={{span: 13}}
                        layout="horizontal"
                    >
                        <Form.Item
                            name="username"
                            label="Логин"
                            initialValue={username}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите логин',
                                },
                                {
                                    validator: checkUsernameAvailability,
                                },
                            ]}
                        >
                            <Input
                                onChange={event => {
                                    setUsername(event.target.value)
                                }}
                                onBlur={username.handleBlur}
                                name="username"
                                type="text"
                                placeholder="Логин"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Пароль"
                            initialValue={password}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите пароль',
                                },
                            ]}
                        >
                            <Input.Password
                                onChange={event => {
                                    setPassword(event.target.value)
                                }}
                                onBlur={password.handleBlur}
                                name="password"
                                type="password"
                                placeholder="Пароль"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password2"
                            label="Подтверждение пароля"
                            initialValue={password}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите пароль',
                                },
                                {
                                    validator: checkPasswords,
                                },
                            ]}
                        >
                            <Input.Password
                                onChange={event => {
                                    setPassword2(event.target.value)
                                }}
                                name="password2"
                                type="password"
                                placeholder="Подтверждение пароля"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="ФИО"
                            initialValue={name}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите ФИО',
                                },
                            ]}
                        >
                            <Input
                                onChange={event => {
                                    setName(event.target.value)
                                }}
                                onBlur={name.handleBlur}
                                name="name"
                                type="text"
                                placeholder="ФИО"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="linkVk"
                            label="Ссылка на ВК"
                            initialValue={linkVk}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите ссылку на ВК',
                                },
                            ]}
                        >
                            <Input
                                onChange={event => {
                                    setLinkVk(event.target.value)
                                }}
                                onBlur={linkVk.handleBlur}
                                name="linkVk"
                                type="text"
                                placeholder="https://vk.com/moroz_zov"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="phoneStudent"
                            label="Номер телефона"
                            initialValue={phoneStudent}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите номер телефона',
                                },
                            ]}
                        >
                            <Input
                                onChange={event => {
                                    setPhoneStudent(event.target.value)
                                }}
                                onBlur={phoneStudent.handleBlur}
                                name="phoneStudent"
                                type="text"
                                placeholder="Номер телефона"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="city"
                            label="Город проживания"
                            initialValue={city}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите город проживания',
                                },
                            ]}
                        >
                            <Input
                                onChange={event => {
                                    setCity(event.target.value)
                                }}
                                onBlur={city.handleBlur}
                                name="city"
                                type="text"
                                placeholder="Город проживания"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <ConfigProvider locale={locale}>
                            <Form.Item
                                name="birthday"
                                label="День рождения"
                                initialValue={birthday}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите день рождения',
                                    },
                                ]}
                            >
                                <DatePicker
                                    format={dateFormat}
                                    onChange={event => {
                                        setBirthday(event.endOf('day'))
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </ConfigProvider>
                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            initialValue={checked}
                            wrapperCol={{
                                offset: 0,
                                span: 25,
                            }}
                            rules={[
                                {
                                    validator: checkCheckBox,
                                },
                            ]}
                        >
                            <Checkbox
                                checked={checked}
                                onChange={(e) => setChecked(e.target.checked)}
                            >
                                Отправляя форму, вы соглашаетесь с <Link to={CONFIDENTIALITY_ROUTE} target='_blank'>политикой
                                конфиденциальности</Link>
                            </Checkbox>
                        </Form.Item>
                        {adding ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <Spin/>
                            </div>
                        ) : (
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    width: '100%',
                                }}>
                                Зарегистрироваться
                            </Button>
                        )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <p>Уже есть аккаунт? <Link to={SIGNIN_ROUTE}>Авторизация</Link></p>
                        </div>
                    </Form>
                    {alertVisible && (
                        <Alert message="Ошибка при создании аккаунта" type="error" closable
                               afterClose={handleClose}
                               style={{marginTop: '1vw',}}/>
                    )}
                </Card>
            </div>
        </Content>
    )
}
