import {Api} from "./index";

export const registerOrder = async (token, groupId, amount) => {
    return await Api.post(`order/register`, {"groupId": groupId, "amount": amount}, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getOrderStatus = async (token, id) => {
    return await Api.get(`order/getStatus/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}