import React from 'react';
import {Route, Routes, Navigate} from "react-router-dom";
import {routes} from "./routes";
import {
    SIGNIN_ROUTE,
    HOME_ROUTE,
    MY_STUDENTS_ROUTE,
    EDIT_GROUP_ROUTE,
    ADD_GROUP_ROUTE,
    HISTORY_ROUTE,
    LESSONS_ROUTE,
    ADD_LESSON_ROUTE,
    LESSON_ROUTE,
    MY_STUDENT_ROUTE,
    EDIT_LESSON_ROUTE,
    PAYMENTS_ROUTE,
    PAY_ROUTE,
    SIGNUP_ROUTE,
    ACCESS_ROUTE,
    TEACHERS_ROUTE,
    ADD_TEACHER_ROUTE,
    STUDENTS_ROUTE,
    EDIT_STUDENT_ROUTE,
    STUDENT_ROUTE,
    GROUPS_ROUTE,
    EDIT_TEACHER_ROUTE,
    BIRTHDAYS_ROUTE,
    HOMEWORKS_ROUTE,
    WAGES_ROUTE,
    ADD_PREMIUM_ROUTE,
    ANALYTICS_ROUTE,
    ADD_PERCENT_ROUTE
} from "./utils";

const AppRouter = () => {
    const user = true
    return (
        <Routes>
            {user === true && routes.map(({path, Component}) =>
                <Route key={path} path={path} element={Component} exact/>
            )}
            <Route path={"*"} element={<Navigate to={SIGNIN_ROUTE}/>}/>
            <Route path={"signin"} element={<Navigate to={SIGNIN_ROUTE}/>}/>
            <Route path={"signup"} element={<Navigate to={SIGNUP_ROUTE}/>}/>
            <Route path={"home"} element={<Navigate to={HOME_ROUTE}/>}/>
            <Route path={"mystudents/:id"} element={<Navigate to={MY_STUDENTS_ROUTE}/>}/>
            <Route path={"editgroup/:id"} element={<Navigate to={EDIT_GROUP_ROUTE}/>}/>
            <Route path={"addgroup"} element={<Navigate to={ADD_GROUP_ROUTE}/>}/>
            <Route path={"history/:id"} element={<Navigate to={HISTORY_ROUTE}/>}/>
            <Route path={"group/:id"} element={<Navigate to={LESSONS_ROUTE}/>}/>
            <Route path={"group/lesson/:id/:number"} element={<Navigate to={LESSON_ROUTE}/>}/>
            <Route path={"group/addlesson/:id"} element={<Navigate to={ADD_LESSON_ROUTE}/>}/>
            <Route path={"group/editlesson/:id/:number"} element={<Navigate to={EDIT_LESSON_ROUTE}/>}/>
            <Route path={"group/student/:groupId/:id"} element={<Navigate to={MY_STUDENT_ROUTE}/>}/>
            <Route path={"homeworks/:id"} element={<Navigate to={HOMEWORKS_ROUTE}/>}/>
            <Route path={"payments/:id"} element={<Navigate to={PAYMENTS_ROUTE}/>}/>
            <Route path={"pay/:id"} element={<Navigate to={PAY_ROUTE}/>}/>
            <Route path={"access/:id"} element={<Navigate to={ACCESS_ROUTE}/>}/>
            <Route path={"teachers"} element={<Navigate to={TEACHERS_ROUTE}/>}/>
            <Route path={"addteacher"} element={<Navigate to={ADD_TEACHER_ROUTE}/>}/>
            <Route path={"students"} element={<Navigate to={STUDENTS_ROUTE}/>}/>
            <Route path={"student/:id"} element={<Navigate to={STUDENT_ROUTE}/>}/>
            <Route path={"editstudent/:id"} element={<Navigate to={EDIT_STUDENT_ROUTE}/>}/>
            <Route path={"editteacher/:id"} element={<Navigate to={EDIT_TEACHER_ROUTE}/>}/>
            <Route path={"groups"} element={<Navigate to={GROUPS_ROUTE}/>}/>
            <Route path={"birthdays"} element={<Navigate to={BIRTHDAYS_ROUTE}/>}/>
            <Route path={"wages"} element={<Navigate to={WAGES_ROUTE}/>}/>
            <Route path={"addpremium"} element={<Navigate to={ADD_PREMIUM_ROUTE}/>}/>
            <Route path={"analytics"} element={<Navigate to={ANALYTICS_ROUTE}/>}/>
            <Route path={"addpercent"} element={<Navigate to={ADD_PERCENT_ROUTE}/>}/>
        </Routes>
    );
};

export default AppRouter;