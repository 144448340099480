import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {isTokenValid} from "../utils/authUtils";
import {SIGNIN_ROUTE} from "../utils";
import {Layout} from "antd";
import Header from "../components/headerComponent/header";
import Footer from "../components/footerComponent/footer";
import AddPremium from "../components/financeComponent/addPremiumComponent";

export default function AddPremiumPage() {

    const navigate = useNavigate();
    let [isAuth, setAuth] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token != null && isTokenValid(token)) {
            setAuth(true);
        } else {
            localStorage.removeItem("token");
            setAuth(false);
            navigate(SIGNIN_ROUTE)
        }
    }, [navigate]);

    return (
        isAuth
            ?
            <Layout>
                <Header/>
                <AddPremium/>
                <Footer/>
            </Layout>
            :
            <p/>
    );
}