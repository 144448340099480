import React, {useState, useEffect} from 'react'
import dayjs from 'dayjs';
import locale from "antd/es/locale/ru_RU";
import "dayjs/locale/ru";
import updateLocale from 'dayjs/plugin/updateLocale';
import {Button, Form, Input, Card, Divider, Spin, DatePicker, ConfigProvider, Alert} from 'antd';
import {getByCurrent, updateTeacher, updateStudent, checkUsername, updateAdmin} from "../../http/UserApi";
import jwtDecode from 'jwt-decode';

export default function Settings() {

    const [username, setUsername] = useState("");
    const [oldUsername, setOldUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [name, setName] = useState("");
    const [phoneTeacher, setPhoneTeacher] = useState("");
    const [phoneStudent, setPhoneStudent] = useState("");
    const [linkVk, setLinkVk] = useState("");
    const [city, setCity] = useState("");
    const [birthday, setBirthday] = useState(dayjs());

    const [adding, setAdding] = useState(false);
    const [loading, setloading] = useState(true);

    const dateFormat = 'DD.MM.YYYY';

    const isTeacher = 'TEACHER'
    const isStudent = 'STUDENT'
    const isAdmin = 'ADMIN'

    const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
    const [alertErrorVisible, setAlertErrorVisible] = useState(false);

    const handleSuccessClose = () => {
        setAlertSuccessVisible(false);
    };

    const handleErrorClose = () => {
        setAlertErrorVisible(false);
    };

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    useEffect(() => {
        getInfo();
    }, []);

    const checkPasswords = async (rule, value) => {
        try {
            if (password === password2) {
                return Promise.resolve();
            } else {
                return Promise.reject('Пароли не совпадают');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const checkUsernameAvailability = async (rule, value) => {
        try {
            const response = await checkUsername(value);
            if (response.data || username === oldUsername) {
                return Promise.resolve();
            } else {
                return Promise.reject('Имя пользователя уже занято');
            }
        } catch (error) {
            console.error(error);
            return Promise.reject('Произошла ошибка при проверке уникальности');
        }
    };

    const getInfo = async () => {
        await getByCurrent(localStorage.getItem('token')).then(
            response => {
                setUsername(response.data.username)
                setOldUsername(response.data.username)
                setName(response.data.name)
                setPhoneTeacher(response.data.phoneTeacher != null ? response.data.phoneTeacher : '')
                setPhoneStudent(response.data.phoneStudent)
                setLinkVk(response.data.linkVk)
                setCity(response.data.city)
                setBirthday(dayjs(response.data.birthday).endOf('day'))
                setloading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    const onFinish = async (values) => {
        if (isTeacher === jwtDecode(localStorage.getItem('token')).role) {
            setAdding(true);
            await updateTeacher(username, password, name, phoneTeacher, linkVk, null, null, localStorage.getItem('token')).then(
                response => {
                    getInfo()
                    setAlertSuccessVisible(true);
                    setAdding(false);
                }).catch(
                error => {
                    setAlertErrorVisible(true);
                    setAdding(false);
                    console.log(error)
                }
            )
        } else if (isStudent === jwtDecode(localStorage.getItem('token')).role) {
            setAdding(true);
            await updateStudent(username, password, name, phoneStudent, linkVk, city, birthday, null, localStorage.getItem('token')).then(
                response => {
                    getInfo()
                    setAlertSuccessVisible(true);
                    setAdding(false);
                }).catch(
                error => {
                    setAlertErrorVisible(true);
                    setAdding(false);
                    console.log(error)
                }
            )
        } else if (isAdmin === jwtDecode(localStorage.getItem('token')).role) {
            setAdding(true);
            await updateAdmin(username, password, name, linkVk, localStorage.getItem('token')).then(
                response => {
                    getInfo()
                    setAlertSuccessVisible(true);
                    setAdding(false);
                }).catch(
                error => {
                    setAlertErrorVisible(true);
                    setAdding(false);
                    console.log(error)
                }
            )
        }
    };

    return (
        <div
            style={{
                minHeight: '100vh',
                display: "flex",
                justifyContent: "center"
            }}
        >
            <div
                style={{}}
            >
                <Divider>Персональные данные</Divider>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <Card
                        style={{display: "inline-block",}}
                    >
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{span: 11}}
                            wrapperCol={{span: 13}}
                            layout="horizontal"
                        >
                            <Form.Item
                                name="username"
                                label="Имя пользователя"
                                initialValue={username}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите имя пользователя',
                                    },
                                    {
                                        validator: checkUsernameAvailability,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setUsername(event.target.value)
                                    }}
                                    onBlur={username.handleBlur}
                                    name="username"
                                    type="text"
                                    placeholder="Имя пользователя"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Пароль"
                                initialValue={password}
                            >
                                <Input.Password
                                    onChange={event => {
                                        setPassword(event.target.value)
                                    }}
                                    onBlur={password.handleBlur}
                                    name="password"
                                    type="password"
                                    placeholder="Пароль"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password2"
                                label="Подтверждение пароля"
                                initialValue={password}
                                rules={[
                                    {
                                        validator: checkPasswords,
                                    },
                                ]}
                            >
                                <Input.Password
                                    onChange={event => {
                                        setPassword2(event.target.value)
                                    }}
                                    name="password2"
                                    type="password"
                                    placeholder="Подтверждение пароля"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label="ФИО"
                                initialValue={name}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите ФИО',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        setName(event.target.value)
                                    }}
                                    onBlur={name.handleBlur}
                                    name="name"
                                    type="text"
                                    placeholder="ФИО"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="linkVk"
                                label="Ссылка на ВК"
                                initialValue={linkVk}
                            >
                                <Input
                                    onChange={event => {
                                        setLinkVk(event.target.value)
                                    }}
                                    onBlur={linkVk.handleBlur}
                                    name="linkVk"
                                    type="text"
                                    placeholder="Ссылка на ВК"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                            {
                                isTeacher === jwtDecode(localStorage.getItem('token')).role &&
                                <Form.Item
                                    name="phoneTeacher"
                                    label="Номер телефона"
                                    initialValue={phoneTeacher}
                                >
                                    <Input
                                        onChange={event => {
                                            setPhoneTeacher(event.target.value)
                                        }}
                                        onBlur={phoneTeacher.handleBlur}
                                        name="phoneTeacher"
                                        type="text"
                                        placeholder="Номер телефона"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            }
                            {
                                isStudent === jwtDecode(localStorage.getItem('token')).role &&
                                <>
                                    <Form.Item
                                        name="phoneStudent"
                                        label="Номер телефона"
                                        initialValue={phoneStudent}
                                    >
                                        <Input
                                            onChange={event => {
                                                setPhoneStudent(event.target.value)
                                            }}
                                            onBlur={phoneStudent.handleBlur}
                                            name="phoneStudent"
                                            type="text"
                                            placeholder="Номер телефона"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="city"
                                        label="Город проживания"
                                        initialValue={city}
                                    >
                                        <Input
                                            onChange={event => {
                                                setCity(event.target.value)
                                            }}
                                            onBlur={city.handleBlur}
                                            name="city"
                                            type="text"
                                            placeholder="Город проживания"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item
                                            name="birthday"
                                            label="День рождения"
                                            initialValue={birthday}
                                        >
                                            <DatePicker
                                                format={dateFormat}
                                                onChange={event => {
                                                    setBirthday(event.endOf('day'))
                                                }}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Form.Item>
                                    </ConfigProvider>
                                </>
                            }
                            {adding ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                    }}>
                                    Сохранить
                                </Button>
                            )}
                        </Form>
                        {alertSuccessVisible && (
                            <Alert message="Успешно сохранено" type="success" closable
                                   afterClose={handleSuccessClose}
                                   style={{marginTop: '1vw',}}/>
                        )}
                        {alertErrorVisible && (
                            <Alert message="Ошибка сохранения" type="error" closable
                                   afterClose={handleErrorClose}
                                   style={{marginTop: '1vw',}}/>
                        )}
                    </Card>
                )}
            </div>
        </div>
    )
}