import {Badge, Calendar, ConfigProvider, Divider, Empty, Spin, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import dayjs from 'dayjs';
import locale from "antd/es/locale/ru_RU";
import "dayjs/locale/ru";
import updateLocale from "dayjs/plugin/updateLocale";
import {STUDENT_ROUTE} from "../../utils";
import {getAllStudentBirthdays} from "../../http/UserApi";

export default function Birthdays() {

    const [birthdays, setBirthdays] = useState([]);
    const [loading, setLoading] = useState(true);

    const dateFormat = 'DD.MM.YYYY';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1,
        dateFormat: dateFormat
    });

    const getBirthdays = async () => {
        await getAllStudentBirthdays(localStorage.getItem('token')).then(
            response => {
                setBirthdays(response.data.map(row => ({
                        id: row.id,
                        name: row.name,
                        date: dayjs(row.birthday),
                        isActive: row.isActive
                    }))
                );
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    useEffect(() => {
        getBirthdays()
    }, []);

    const compareIsActiveAndDatesIgnoringYear = (a, b) => {
        if (a.isActive && !b.isActive) return -1;
        if (!a.isActive && b.isActive) return 1;
        const dateA = dayjs(a.date).format('MM-DD');
        const dateB = dayjs(b.date).format('MM-DD');
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
    };

    const dateCellRender = (value) => {
        const date = value.format('MM-DD');
        const birthdaysOnDate = birthdays.filter(birthday => dayjs(birthday.date).format('MM-DD') === date);
        return (
            <ul style={{
                listStyle: 'none', margin: 0, padding: 0
            }}>
                {birthdaysOnDate.sort(compareIsActiveAndDatesIgnoringYear).map((item) => (
                    <li key={item.id}>
                        <Badge
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                            status={item.isActive ? "success" : "default"}
                            text={<Link isActive={false} title={item.name} to={`${STUDENT_ROUTE}/${item.id}`}
                                        className="custom-link"> {getInitials(item.name)}</Link>}/>
                    </li>
                ))}
            </ul>
        );
    };

    const getInitials = (name) => {
        let splitName = name.split(' ')
        return `${splitName[0]} ${splitName[1] ? splitName[1][0] + "." : ""}${splitName[2] ? splitName[2][0] + "." : ""}`
    }

    const monthCellRender = (value) => {
        const date = value.format('MM');
        const birthdaysOnDate = birthdays.filter(birthday => dayjs(birthday.date).format('MM') === date);
        return (
            <ul style={{
                listStyle: 'none', margin: 0, padding: 0
            }}>
                {birthdaysOnDate.sort(compareIsActiveAndDatesIgnoringYear).map((item) => (
                    <li key={item.id}>
                        <Badge
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                            status={item.isActive ? "success" : "default"}
                            text={<Link title={item.name} to={`${STUDENT_ROUTE}/${item.id}`}
                                        className="custom-link">{item.name} ({dayjs(item.date).format(dateFormat)})</Link>}/>
                    </li>
                ))}
            </ul>
        );
    };

    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Divider>Дни рождения</Divider>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider locale={locale}>
                    <Calendar
                        className={"custom-calendar"}
                        cellRender={cellRender}
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                </ConfigProvider>
            )
            }
        </div>
    )
}