export const LANDING_ROUTE = 'https://itpark32.ru'
// export const BACKEND_ROUTE = 'http://localhost:8090'
export const BACKEND_ROUTE = 'https://itpark32sys.ru/lk'
export const CONFIDENTIALITY_ROUTE = 'https://itpark32.ru/assets/privacysys.pdf'

export const SIGNIN_ROUTE = '/signin'
export const SIGNUP_ROUTE = '/signup'
export const HOME_ROUTE = '/home'
export const MY_STUDENTS_ROUTE = '/mystudents'
export const SETTINGS_ROUTE = '/settings'
export const EDIT_GROUP_ROUTE = '/editgroup'
export const ADD_GROUP_ROUTE = '/addgroup'
export const HISTORY_ROUTE = '/history'
export const LESSONS_ROUTE = '/group'
export const HOMEWORKS_ROUTE = '/homeworks'
export const MY_STUDENT_ROUTE = '/group/student'
export const LESSON_ROUTE = '/group/lesson'
export const EDIT_LESSON_ROUTE = '/group/editlesson'
export const ADD_LESSON_ROUTE = '/group/addlesson'
export const PAYMENTS_ROUTE = '/payments'
export const PAY_ROUTE = '/pay'
export const ACCESS_ROUTE = '/access'
export const TEACHERS_ROUTE = '/teachers'
export const ADD_TEACHER_ROUTE = '/addteacher'
export const STUDENTS_ROUTE = '/students'
export const STUDENT_ROUTE = '/student'
export const EDIT_STUDENT_ROUTE = '/editstudent'
export const EDIT_TEACHER_ROUTE = '/editteacher'
export const GROUPS_ROUTE = '/groups'
export const BIRTHDAYS_ROUTE = '/birthdays'
export const WAGES_ROUTE = '/wages'
export const ADD_PREMIUM_ROUTE = '/addpremium'
export const ANALYTICS_ROUTE = '/analytics'
export const ADD_PERCENT_ROUTE = '/addpercent'