import React, {useState, useEffect} from 'react'
import {getById} from "../../http/UserApi";
import {getForMove} from "../../http/GroupApi";
import {move, addPayment, addBonuses} from "../../http/StudentGroupApi";
import {getAllTransactionsPageByStudent, getTransactionsPage} from "../../http/TransactionsApi";
import {
    Button,
    Spin,
    Divider,
    ConfigProvider,
    Table,
    Select,
    Form,
    InputNumber,
    Empty,
    QRCode,
    Popover,
    Alert
} from 'antd';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {BookOutlined, LeftOutlined, StarOutlined, VideoCameraOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import {getAllByIdAndCurrentStudent, getAllByStudentId} from "../../http/StudentLessonApi";

export default function Student() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [name, setName] = useState('');
    const [paginationTransactions, setPaginationTransactions] = useState({current: 1, pageSize: 10, total: 0});
    const [sortTransactions, setSortTransactions] = useState('date,desc');
    const [paginationLessons, setPaginationLessons] = useState({current: 1, pageSize: 10, total: 0});
    const [sortLessons, setSortLessons] = useState('date,desc');

    const [info, setInfo] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [lessons, setLessons] = useState([]);

    const columns = [
        {
            title: 'Графа',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
        },
    ];
    const columnsTransactions = [
        {
            title: 'Группа',
            dataIndex: 'group',
            key: 'group',
            sorter: true
        },
        {
            title: 'Дата перевода',
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            align: 'center',
        },
        {
            title: 'Сумма перевода',
            dataIndex: 'sumTransaction',
            key: 'sumTransaction',
            sorter: true
        },
        {
            title: 'Сумма бонусов',
            dataIndex: 'bonusSumTransaction',
            key: 'bonusSumTransaction',
            sorter: true
        },
        {
            title: 'Тип оплаты',
            dataIndex: 'type',
            key: 'type',
            sorter: true
        },
    ];

    const columnsLessons = [
        {
            title: 'Группа',
            dataIndex: 'group',
            key: 'group',
            sorter: true
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            align: 'center',
        },
        {
            title: 'Тема урока',
            dataIndex: 'lessonTheme',
            key: 'lessonTheme',
            width: 300,
            sorter: true
        },
        {
            title: 'Домашнее задание',
            dataIndex: 'homework',
            key: 'homework',
            width: 500,
            sorter: true
        },
        {
            title: 'Оплата',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            sorter: true
        },
        {
            title: 'Стоимость урока',
            dataIndex: 'cost',
            key: 'cost'
        },
        {
            title: 'Баланс после списания',
            dataIndex: 'balanceLesson',
            key: 'balanceLesson',
            sorter: true
        },
        {
            title: 'Бонусный баланс после списания',
            dataIndex: 'bonusBalanceLesson',
            key: 'bonusBalanceLesson',
            sorter: true
        },
    ];

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingTransactions, setLoadingTransactions] = useState(true);

    const dateFormat = 'DD.MM.YYYY';
    const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });

    function getSortOrder(order) {
        switch (order) {
            case 'ascend':
                return 'asc';
            case 'descend':
                return 'desc';
            default:
                return 'asc';
        }
    }

    function getTypeText(type, order_id) {
        switch (type) {
            case 'MANUALLY':
                return 'Вручную';
            case 'ORDER':
                return `Через карту (заказ №${order_id})`;
            default:
                return 'Неизвестный тип';
        }
    }

    const getStudentInfo = async () => {
        const responseInfo = [];
        await getById(localStorage.getItem('token'), params.id,).then(
            response => {
                responseInfo.push({
                    key: 'Номер аккаунта',
                    value: response.data.id,
                });
                responseInfo.push({
                    key: 'Имя пользователя',
                    value: response.data.username,
                });
                responseInfo.push({
                    key: 'ФИО',
                    value: response.data.name,
                });
                responseInfo.push({
                    key: 'Ссылка на ВК',
                    value: <Popover overlayInnerStyle={{padding: 0}}
                                    content={<QRCode value={response.data.linkVk} bordered={false}/>}>
                        <Link
                            to={(response.data.linkVk.startsWith('http://') || response.data.linkVk.startsWith('https://')) ? response.data.linkVk : `https://${response.data.linkVk}`}
                            target='_blank' className="custom-link">{response.data.linkVk}</Link></Popover>,
                });
                responseInfo.push({
                    key: 'Номер телефона',
                    value: response.data.phoneStudent,
                });
                responseInfo.push({
                    key: 'Город проживания',
                    value: response.data.city,
                });
                responseInfo.push({
                    key: 'День рождения',
                    value: dayjs(response.data.birthday).format(dateFormat),
                });
                setInfo(responseInfo);
                setName(response.data.name);
                setLoading(false);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const getTransactions = async () => {
        const transactionsUpdate = [];
        await getAllTransactionsPageByStudent(localStorage.getItem('token'), params.id, paginationTransactions.current - 1, paginationTransactions.pageSize, sortTransactions).then(
            response => {
                response.data.content.forEach(transaction => {
                    transactionsUpdate.push({
                        key: transaction.id,
                        group: transaction.group.name,
                        date: dayjs(transaction.date).format(dateTimeFormat),
                        sumTransaction: `${transaction.sumTransaction}₽`,
                        bonusSumTransaction: `${transaction.bonusSumTransaction}₽ бонусов`,
                        type: getTypeText(transaction.type, transaction.order !== null ? transaction.order.id : null)
                    });
                });
                setTransactions(transactionsUpdate);
                setPaginationTransactions({...paginationTransactions, total: response.data.totalElements});
            }).catch(
            error => {
                console.log(error);
            }
        );
        setLoadingTransactions(false);
    };

    useEffect(() => {
        getTransactions();
    }, [paginationTransactions.current, paginationTransactions.pageSize, sortTransactions]);

    const getLessons = async () => {
        await getAllByStudentId(localStorage.getItem('token'), params.id, paginationLessons.current - 1, paginationLessons.pageSize, sortLessons).then(
            response => {
                setLessons(
                    response.data.content.map(row => ({
                        group: row.group.name,
                        date: dayjs(row.date).format(dateFormat),
                        lessonTheme: row.lessonTheme,
                        homework: row.homework,
                        paymentStatus:
                            <>
                                {row.paymentStatus === 'ATTEND_LESSON' &&
                                    <><BookOutlined style={{color: '#04879C'}}/> Посещал занятие</>
                                }
                                {row.paymentStatus === 'WATCH_VIDEO' &&
                                    <><VideoCameraOutlined style={{color: '#F30A49'}}/> Смотрел видео</>
                                }
                                {row.paymentStatus === 'OWN_PRICE' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">1</span>
                                        </div>
                                        Занимался по особой цене
                                    </>
                                }
                                {row.paymentStatus === 'OWN_PRICE2' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">2</span>
                                        </div>
                                        Занимался по особой цене 2
                                    </>                                }
                                {row.paymentStatus === 'OWN_PRICE3' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">3</span>
                                        </div>
                                        Занимался по особой цене 3
                                    </>                                }
                                {row.paymentStatus === 'OWN_PRICE4' &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">4</span>
                                        </div>
                                        Занимался по особой цене 4
                                    </>
                                }
                            </>,
                        cost: `${row.cost}₽`,
                        balanceLesson: `${row.balanceLesson}₽`,
                        bonusBalanceLesson: `${row.bonusBalanceLesson}₽ бонусов`,
                    }))
                );
                setPaginationLessons({...paginationLessons, total: response.data.totalElements});
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    useEffect(() => {
        getLessons();
    }, [paginationLessons.current, paginationLessons.pageSize, sortLessons]);

    const handleTableTransactionsChange = (pagination, filters, sorter) => {
        setPaginationTransactions(pagination);
        if (sorter.column !== undefined && sorter.order !== undefined) {
            setSortTransactions(`${sorter.field},${getSortOrder(sorter.order)}`);
        } else {
            setSortTransactions("");
        }
    };

    const handleTableLessonsChange = (pagination, filters, sorter) => {
        setPaginationLessons(pagination);
        if (sorter.column !== undefined && sorter.order !== undefined) {
            setSortLessons(`${sorter.field},${getSortOrder(sorter.order)}`);
        } else {
            setSortLessons("");
        }
    };

    useEffect(() => {
        getStudentInfo();
        getTransactions();
        getLessons();
    }, []);


    return (
        <div
            style={{
                minHeight: '100vh',
                margin: '1vw',
            }}
        >
            <Button onClick={goBack} style={{}}><LeftOutlined/> Назад</Button>
            <Divider>{name}</Divider>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет информации'/>}>
                    <Table
                        columns={columns}
                        dataSource={info}
                        pagination={false}
                        bordered
                        scroll={{x: "max-content"}}
                        style={{
                            width: '100%',
                        }}
                    />
                </ConfigProvider>
            )}
            <Divider>История оплат</Divider>
            {loadingTransactions ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет оплат'/>}>
                    <Table
                        columns={columnsTransactions
                        }
                        dataSource={transactions}
                        pagination={paginationTransactions}
                        bordered
                        onChange={handleTableTransactionsChange}
                        scroll={{x: "max-content"}}
                        style={{
                            width: '100%',
                        }}
                    />
                </ConfigProvider>
            )}
            <Divider>История посещений</Divider>
            <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет занятий'/>}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <Table
                        columns={columnsLessons}
                        dataSource={lessons}
                        pagination={paginationLessons}
                        bordered
                        onChange={handleTableLessonsChange}
                        scroll={{x: "max-content"}}
                        style={{
                            width: '100%',
                        }}
                    />
                )}
            </ConfigProvider>
        </div>
    )
}