import React, {useState, useEffect} from 'react'
import {getNameById} from "../../http/GroupApi";
import {getTransactionsPageByCurrentStudent} from "../../http/TransactionsApi";
import {Button, Spin, Divider, ConfigProvider, Table, Empty} from 'antd';
import {useParams, useNavigate} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';

export default function Payments() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const params = useParams();

    const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss';

    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [groupName, setGroupName] = useState('');

    function getTypeText(type, order_id) {
        switch (type) {
            case 'MANUALLY':
                return 'Вручную';
            case 'ORDER':
                return `Через карту (заказ №${order_id})`;
            default:
                return 'Неизвестный тип';
        }
    }

    const columnsTransactions = [
        {
            title: 'Дата оплаты',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
        },
        {
            title: 'Сумма оплаты',
            dataIndex: 'sumTransaction',
            key: 'sumTransaction',
        },
        {
            title: 'Сумма бонусов',
            dataIndex: 'bonusSumTransaction',
            key: 'bonusSumTransaction',
        },
        {
            title: 'Тип оплаты',
            dataIndex: 'type',
            key: 'type',
        },
    ];

    useEffect(() => {
        getName();
    }, []);

    useEffect(() => {
        getTransactions();
    }, [pagination.current, pagination.pageSize]);

    const getName = async () => {
        await getNameById(localStorage.getItem('token'), params.id).then(
            response => {
                setGroupName(response.data);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getTransactions = async () => {
        const transactionsUpdate = [];
        await getTransactionsPageByCurrentStudent(localStorage.getItem('token'), params.id, pagination.current - 1, pagination.pageSize).then(
            response => {
                response.data.content.forEach(transaction => {
                    transactionsUpdate.push({
                        key: transaction.id,
                        date: dayjs(transaction.date).format(dateTimeFormat),
                        sumTransaction: `${transaction.sumTransaction}₽`,
                        bonusSumTransaction: `${transaction.bonusSumTransaction}₽ бонусов`,
                        type: getTypeText(transaction.type, transaction.order !== null ? transaction.order.id : null)
                    });
                });
                setTransactions(transactionsUpdate)
                setPagination({...pagination, total: response.data.totalElements});
            }).catch(
            error => {
                console.log(error)
            }
        );
        setLoadingTransactions(false);
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <Divider>История оплат ({groupName})</Divider>
            {loadingTransactions ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет оплат'/>}>

                    <Table
                        columns={columnsTransactions
                        }
                        dataSource={transactions}
                        pagination={pagination}
                        bordered
                        onChange={handleTableChange}
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                </ConfigProvider>
            )}
        </div>
    )
}