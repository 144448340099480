import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {isTokenValid} from "../utils/authUtils";
import jwtDecode from "jwt-decode";
import {HOME_ROUTE, SIGNIN_ROUTE} from "../utils";
import {Layout} from "antd";
import Header from "../components/headerComponent/header";
import Footer from "../components/footerComponent/footer";
import EditTeacher from "../components/structureComponent/editTeacherComponent";

export default function EditTeacherPage() {

    const navigate = useNavigate();
    let [isAuth, setAuth] = useState(false)
    const isAdmin = 'ADMIN';

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token != null && isTokenValid(token)) {
            if (isAdmin !== jwtDecode(localStorage.getItem('token')).role) {
                navigate(HOME_ROUTE)
            }
            setAuth(true);
        } else {
            localStorage.removeItem("token");
            setAuth(false);
            navigate(SIGNIN_ROUTE)
        }
    }, [navigate]);

    return (
        isAuth
            ?
            <Layout>
                <Header/>
                <EditTeacher/>
                <Footer/>
            </Layout>
            :
            <p/>
    );
}