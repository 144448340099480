import {Button, ConfigProvider, Divider, Empty, Popover, QRCode, Spin, Table} from "antd";
import {
    ADD_TEACHER_ROUTE,
    EDIT_TEACHER_ROUTE,
} from "../../utils";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {getAllUsersByRole} from "../../http/UserApi";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

export default function Teachers() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [teachers, setTeachers] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});

    const columns = [
        {
            title: 'Номер аккаунта',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Логин',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Телефон',
            dataIndex: 'phoneTeacher',
            key: 'phoneTeacher',
        },
        {
            title: 'Страница ВК',
            dataIndex: 'linkVk',
            key: 'linkVk',
        },
        {
            title: 'Статус',
            dataIndex: 'isActive',
            key: 'isActive',
        },
        {
            title: 'Редактировать данные',
            dataIndex: 'editStudent',
            key: 'editStudent',
            align: 'center',
        }
    ];


    const getTeachers = async () => {
        await getAllUsersByRole(localStorage.getItem('token'), "TEACHER", pagination.current - 1, pagination.pageSize).then(
            response => {
                setTeachers(
                    response.data.content.map(row => ({
                        id: row.id,
                        username: row.username,
                        name: row.name,
                        phoneTeacher: row.phoneTeacher,
                        linkVk: <Popover overlayInnerStyle={{padding: 0}}
                                         content={<QRCode value={row.linkVk} bordered={false}/>}>
                            <Link
                                to={(row.linkVk.startsWith('http://') || row.linkVk.startsWith('https://')) ? row.linkVk : `https://${row.linkVk}`}
                                target='_blank' className="custom-link">{row.linkVk}</Link></Popover>,
                        // isActive: row.isActive ? <CheckOutlined/> : <CloseOutlined/>,
                        isActive: row.isActive ? "Активен" : "Заблокирован",
                        editStudent: <Button
                            onClick={() => navigate(`${EDIT_TEACHER_ROUTE}/${row.id}`)}>Редактировать</Button>,
                    }))
                );
                setPagination({...pagination, total: response.data.totalElements});
                setLoading(false);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        getTeachers();
    }, [pagination.current, pagination.pageSize]);

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Divider>Преподаватели</Divider>
            <Button onClick={() => navigate(`${ADD_TEACHER_ROUTE}`)} style={{
                margin: '1vw',
                display: 'block'
            }}>Добавить преподавателя</Button>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет преподавателей'/>}>

                    <Table
                        columns={columns}
                        dataSource={teachers}
                        pagination={pagination}
                        bordered
                        onChange={handleTableChange}
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                </ConfigProvider>
            )}
        </div>
    )
}
