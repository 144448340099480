import {
    Alert,
    Button,
    Card,
    ConfigProvider,
    DatePicker,
    Divider,
    Empty,
    Form,
    InputNumber,
    Select, Spin,
} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getAllByTeacher} from "../../http/GroupApi";
import locale from "antd/es/locale/ru_RU";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import {getAllTeachers} from "../../http/UserApi";
import {create} from "../../http/PercentApi";

export default function AddPercent() {
    const [form] = Form.useForm(); // Создаем ссылку на форму

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [date, setDate] = useState(dayjs());
    const [amount, setAmount] = useState(0);
    const [teachers, setTeachers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState();
    const [selectedGroup, setSelectedGroup] = useState();

    const [loadingTeachers, setLoadingTeachers] = useState(true);
    const [loadingGroups, setLoadingGroups] = useState(false);
    const [adding, setAdding] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);

    const dateFormat = 'DD.MM.YYYY';

    dayjs.extend(updateLocale);
    dayjs.updateLocale("ru", {
        weekStart: 1
    });


    const getTeachers = async () => {
        const optionsUpdate = [];
        await getAllTeachers(localStorage.getItem('token')).then(
            response => {
                response.data.forEach(teacher => {
                    optionsUpdate.push({
                        label: teacher.name,
                        value: teacher.id.toString(),
                        key: teacher.id
                    });
                });
                setTeachers(optionsUpdate);
                setLoadingTeachers(false);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const getTeacherGroups = async () => {
        const optionsUpdate = [];
        await getAllByTeacher(localStorage.getItem('token'), selectedTeacher, true).then(
            response => {
                response.data.forEach(group => {
                    optionsUpdate.push({
                        label: group.name,
                        value: group.id.toString(),
                        key: group.id
                    });
                });
                setGroups(optionsUpdate);
                setLoadingGroups(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const handleClose = () => {
        setAlertVisible(false);
    };

    useEffect(() => {
        getTeachers()
    }, []);

    useEffect(() => {
        setSelectedGroup();
        getTeacherGroups();
    }, [selectedTeacher]);

    const onFinish = async (values) => {
        setAdding(true);
        await create(date, amount, selectedTeacher, selectedGroup, localStorage.getItem('token')).then(
            response => {
                goBack()
            }).catch(
            error => {
                setAlertVisible(true);
                setAdding(false);
                console.log(error)
            }
        )
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{}}
                >
                    <Divider>Установка процента</Divider>
                    <Card
                        style={{display: "inline-block",}}
                    >
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{span: 11}}
                            wrapperCol={{span: 13}}
                            layout="horizontal"
                        >
                            <ConfigProvider locale={locale}>
                                <Form.Item
                                    name="birthday"
                                    label="Дата"
                                    initialValue={date}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Выберите дату',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        format={dateFormat}
                                        onChange={event => {
                                            setDate(event.endOf('day'))
                                        }}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </ConfigProvider>
                            <Form.Item
                                name="costOwn"
                                label="Процент"
                                initialValue={amount}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите процент',
                                    },
                                ]}
                            >
                                <InputNumber
                                    onChange={event => {
                                        setAmount(event)
                                    }}
                                    min={0}
                                    max={100}
                                    suffix="%"
                                    placeholder="Процент"
                                    style={{
                                        width: '100%'
                                    }}/>
                            </Form.Item>
                            <Form.Item
                                name="teacher"
                                label="Преподаватель"
                            >
                                <ConfigProvider
                                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                              description='Нет преподавателей'/>}>
                                    <Select
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder="Выберите преподавателя"
                                        loading={loadingTeachers}
                                        optionFilterProp="label"
                                        onChange={event => {
                                            setSelectedTeacher(event)
                                            setLoadingGroups(true)
                                        }}
                                        options={teachers}
                                    />
                                </ConfigProvider>
                            </Form.Item>
                            <Form.Item
                                name="group"
                                label="Группа"
                            >
                                <ConfigProvider
                                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                              description='Нет групп'/>}>
                                    <Select
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder="Выберите группу"
                                        loading={loadingGroups}
                                        optionFilterProp="label"
                                        value={selectedGroup}
                                        onChange={event => {
                                            setSelectedGroup(event)
                                        }}
                                        options={groups}
                                    />
                                </ConfigProvider>
                            </Form.Item>
                            {adding ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                    }}>
                                    Установить
                                </Button>
                            )}
                        </Form>
                        {alertVisible && (
                            <Alert message="Ошибка установки" type="error" closable
                                   afterClose={handleClose}
                                   style={{marginTop: '1vw',}}/>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    );
}