import {Api} from "./index";

export const getAllByIdAndCurrentStudent = async (token, id) => {
    return await Api.get(`student-lesson/by-student/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getAllByStudentId = async (token, id, page, size, sort) => {
    return await Api.get(`student-lesson/by-student/all/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "page": page,
            "size": size,
            "sort": sort
        }
    })
}

export const getLessonsByGroupId = async (token, id) => {
    return await Api.get(`student-lesson/lessons/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getAllByGroupId = async (token, id) => {
    return await Api.get(`student-lesson/by-group/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const getAllByGroupIdAndLessonNumber = async (token, id, number) => {
    return await Api.get(`student-lesson/group/${id}/lesson/${number}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const add = async (token, id, date, homework, lessonTheme, lessonCost, costVideo, costOwn, costOwn2, costOwn3, costOwn4, selectedStatuses) => {
    const data = []
    Object.keys(selectedStatuses).forEach((key) => {
        data.push({
            "date": date,
            "homework": homework,
            "lessonTheme": lessonTheme,
            "lessonCost": lessonCost,
            "costVideo": costVideo,
            "costOwn": costOwn,
            "costOwn2": costOwn2,
            "costOwn3": costOwn3,
            "costOwn4": costOwn4,
            "paymentStatus": selectedStatuses[key],
            "groupId": id,
            "studentId": key
        })
    });
    return await Api.post(`student-lesson`, data, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const edit = async (token, id, date, homework, lessonTheme, lessonCost, costVideo, costOwn, costOwn2, costOwn3, costOwn4, selectedStatuses) => {
    const data = []
    Object.keys(selectedStatuses).forEach((key) => {
        data.push({
            "date": date,
            "homework": homework,
            "lessonTheme": lessonTheme,
            "lessonCost": lessonCost,
            "costVideo": costVideo,
            "costOwn": costOwn,
            "costOwn2": costOwn2,
            "costOwn3": costOwn3,
            "costOwn4": costOwn4,
            "paymentStatus": selectedStatuses[key],
            "groupId": id,
            "studentId": key
        })
    });
    return await Api.put(`student-lesson`, data, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const updateHomeworkIsDone = async (token, id, isDone) => {
    return await Api.put(`student-lesson/homework`, {"id": id, "isDone": isDone}, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const checkDate = async (token, id, date) => {
    return await Api.get(`student-lesson/check-date`, {
        headers: {
            "Authorization": "Bearer " + token
        },
        params: {
            "group_id": id,
            "date": date
        }
    })
}