import {Api} from "./index";

export const exclude = async (token, id) => {
    return await Api.delete(`student-group/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const restore = async (token, id) => {
    return await Api.get(`student-group/restore/${id}`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const addSome = async (token, studentIds, groupId) => {
    return await Api.post(`student-group/some`, {"studentIds": studentIds, "groupId": groupId}, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}

export const move = async (token, studentId, groupIdFrom, groupIdTo) => {
    return await Api.put(`student-group/move`, {
        "studentId": studentId,
        "groupIdFrom": groupIdFrom,
        "groupIdTo": groupIdTo
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}


export const addPayment = async (groupId, studentId, sumTransaction, date, token) => {
    const {data} = await Api.post(`student-group/add-payment`, {
        "groupId": groupId,
        "studentId": studentId,
        "sumTransaction": sumTransaction,
        "date": date
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return data
}

export const addBonuses = async (groupId, studentId, sumTransaction, date, token) => {
    const {data} = await Api.post(`student-group/add-bonuses`, {
        "groupId": groupId,
        "studentId": studentId,
        "sumTransaction": sumTransaction,
        "date": date
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return data
}