import React, {useState} from 'react'
import {Layout, Button, Form, Input, Card, Alert, Typography, Divider, Modal} from 'antd';
import {login} from "../../http/UserApi";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import {LANDING_ROUTE, SIGNUP_ROUTE} from '../../utils';
import {LeftOutlined} from "@ant-design/icons";

const {Paragraph} = Typography;

export default function Signin() {
    const {Content} = Layout;
    const navigate = useNavigate();

    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertDisabledVisible, setAlertDisabledVisible] = useState(false);
    const handleClose = () => {
        setAlertVisible(false);
    };
    const handleDisabledClose = () => {
        setAlertDisabledVisible(false);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    const onFinish = async (values) => {
        let credentials = {
            username: username,
            password: password
        }
        await login(username, password).then(
            response => {
                let token = response.data
                navigate('/home')

            }).catch(
            error => {
                if (error.response && error.response.status === 403) {
                    setAlertDisabledVisible(true);
                } else {
                    setAlertVisible(true);
                }
            }
        )
    };

    const payments = () => {
        Modal.info({
            width: '1000px',
            centered: true,
            title: 'Способы оплаты',
            content: (
                <div>
                    < Paragraph>
                        Для оплаты занятий с помощью банковской карты на странице личного кабинета необходимо нажать
                        кнопку Пополнить баланс. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт
                        следующих платёжных систем:
                        <ul>
                            <li>
                                МИР
                            </li>
                            <li>
                                VISA International
                            </li>
                            <li>
                                Mastercard Worldwide
                            </li>
                        </ul>
                        Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО
                        СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме
                        с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию
                        безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept,
                        J-Secure для проведения платежа также может потребоваться ввод специального пароля. Настоящий
                        сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации
                        обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за
                        исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским
                        картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int.,
                        MasterCard Europe Sprl, JCB.
                    </Paragraph>
                </div>
            ),
            onOk() {
            },
        });
    };

    const refund = () => {
        Modal.info({
            width: '1000px',
            centered: true,
            title: 'Возврат денежных средств',
            content: (
                <div>
                    <Paragraph>
                        Возврат переведённых средств, производится на ваш банковский счёт в течение 5-30 рабочих дней
                        (срок зависит от банка, который выдал вашу банковскую карту).
                    </Paragraph>
                </div>
            ),
            onOk() {
            },
        });
    };

    return (
        <Content
            className="site-layout"
            style={{
                padding: 0,
            }}
        >
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Card className="signin"
                      title={<div>
                          <Button type="text" href={LANDING_ROUTE}><LeftOutlined/></Button>
                          Авторизация</div>}
                      style={{display: "inline-block", margin: "auto"}}
                >
                    {alertVisible && (
                        <Alert message="Неверный логин или пароль" type="error" closable
                               afterClose={handleClose}
                               style={{marginBottom: '1vw',}}/>
                    )}
                    {alertDisabledVisible && (
                        <Alert message="Ваш аккаунт заблокирован" type="error" closable
                               afterClose={handleDisabledClose}
                               style={{marginBottom: '1vw',}}/>
                    )}
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            name="username"
                            initialValue={username}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите имя пользователя',
                                }
                            ]}
                        >
                            <Input
                                onChange={event => {
                                    setUsername(event.target.value)
                                }}
                                name="username"
                                type="text"
                                placeholder="Имя пользователя"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            initialValue={password}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите пароль',
                                },
                            ]}
                            style={{marginBottom: 0}}
                        >
                            <Input.Password
                                onChange={event => {
                                    setPassword(event.target.value)
                                }}
                                onBlur={password.handleBlur}
                                name="password"
                                type="password"
                                placeholder="Пароль"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 0.5
                }}
              >
                <Checkbox>Запомнить меня</Checkbox>
              </Form.Item> */}
                            <Paragraph>
                                Для восстановления пароля
                                <Link to={"https://vk.com/itpark32_official"} target='_blank'> свяжитесь<br/>
                                    с администратором</Link>
                            </Paragraph>
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                width: '100%',
                            }}>
                            Авторизоваться
                        </Button>
                        <Link to={SIGNUP_ROUTE}>Зарегистрироваться</Link><br/>
                        {/*<Divider/>*/}
                        {/*<Link onClick={payments}>Способы оплаты</Link><br/>*/}
                        {/*<Link onClick={refund}> Возврат денежных средств</Link>*/}
                    </Form>
                </Card>
            </div>
        </Content>
    )
}
