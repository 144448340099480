import React, {useState, useEffect} from 'react'
import {getAllStudentsByGroupId, getAllNewStudentsByGroupId} from "../../http/UserApi";
import {exclude, restore, addSome} from "../../http/StudentGroupApi";
import {Button, Table, Spin, Divider, ConfigProvider, Empty, Select, Row, Col, Alert} from 'antd';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';
import {MY_STUDENT_ROUTE} from '../../utils';

export default function MyStudents() {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const params = useParams();
    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Баланс',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Бонусный баланс',
            dataIndex: 'bonusBalance',
            key: 'bonusBalance',
        },
        {
            title: 'Исключить',
            dataIndex: 'exclude',
            key: 'exclude',
            align: 'center',
        },
    ];

    const inactiveColumns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Баланс',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Бонусный баланс',
            dataIndex: 'bonusBalance',
            key: 'bonusBalance',
        },
        {
            title: 'Восстановить',
            dataIndex: 'restore',
            key: 'restore',
            align: 'center',
        },
    ];

    const [options, setOptions] = useState([]);

    const [selectedStudents, setSelectedStudents] = useState([]);
    const [students, setStudents] = useState([]);
    const [inactiveStudents, setInactiveStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingInactive, setLoadingInactive] = useState(true);
    const [loadingSelect, setLoadingSelect] = useState(true);

    const [alertAddVisible, setAlertAddVisible] = useState(false);
    const [alertExcludeVisible, setAlertExcludeVisible] = useState(false);
    const [alertRestoreVisible, setAlertRestoreVisible] = useState(false);

    const handleAddClose = () => {
        setAlertAddVisible(false);
    };

    const handleExcludeClose = () => {
        setAlertExcludeVisible(false);
    };

    const handleRestoreClose = () => {
        setAlertRestoreVisible(false);
    };

    useEffect(() => {
        getGroupStudents();
        getInactiveGroupStudents();
        getStudents();
    }, []);

    const excludeStudent = async (id) => {
        await exclude(localStorage.getItem('token'), id).then(
            response => {
                getGroupStudents();
                getInactiveGroupStudents();
                setAlertExcludeVisible(true);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const restoreStudent = async (id) => {
        await restore(localStorage.getItem('token'), id).then(
            response => {
                getGroupStudents();
                getInactiveGroupStudents();
                setAlertRestoreVisible(true);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const addStudents = async (id) => {
        await addSome(localStorage.getItem('token'), selectedStudents, params.id).then(
            response => {
                getGroupStudents();
                getInactiveGroupStudents();
                getStudents();
                setSelectedStudents([]);
                setAlertAddVisible(true);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const getStudents = async () => {
        const optionsUpdate = [];
        await getAllNewStudentsByGroupId(localStorage.getItem('token'), params.id).then(
            response => {
                response.data.forEach(student => {
                    optionsUpdate.push({
                        label: student.name,
                        value: student.id.toString(),
                        key: student.id
                    });
                });
                setOptions(optionsUpdate);
                setLoadingSelect(false);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    const getGroupStudents = async () => {
        await getAllStudentsByGroupId(localStorage.getItem('token'), params.id, true).then(
            response => {
                setStudents(
                    response.data.map(row => ({
                        name: <Link to={`${MY_STUDENT_ROUTE}/${params.id}/${row.student.id}`}
                                    className="custom-link">{row.student.name}</Link>,
                        balance: `${row.balance}₽`,
                        bonusBalance: `${row.bonusBalance}₽ бонусов`,
                        exclude: <Button type="primary" danger
                                         onClick={() => excludeStudent(row.id)}>Исключить</Button>,
                        key: row.id
                    }))
                );
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getInactiveGroupStudents = async () => {
        await getAllStudentsByGroupId(localStorage.getItem('token'), params.id, false).then(
            response => {
                setInactiveStudents(
                    response.data.map(row => ({
                        name: <Link to={`${MY_STUDENT_ROUTE}/${params.id}/${row.student.id}`}
                                    className="custom-link">{row.student.name}</Link>,
                        balance: `${row.balance}₽`,
                        bonusBalance: `${row.bonusBalance}₽ бонусов`,
                        restore: <Button
                            onClick={() => restoreStudent(row.id)}>Восстановить</Button>,
                        key: row.id
                    }))
                );
                setLoadingInactive(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <Divider>Ученики</Divider>
            <ConfigProvider
                renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет учеников'/>}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={students}
                        pagination={false}
                        bordered
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                        footer={() =>
                            loadingSelect ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spin/>
                                </div>
                            ) : (
                                <>
                                    <Row justify="left" align="middle" style={{
                                        width: '100%'
                                    }}>
                                        <Col span={21} style={{marginRight: '1vw'}}>
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                style={{width: '100%'}}
                                                placeholder="Выберите учеников"
                                                defaultValue={[]}
                                                optionFilterProp="label"
                                                onChange={event => {
                                                    setSelectedStudents(event)
                                                }}
                                                value={selectedStudents}
                                                options={options}
                                            />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => addStudents()}>Добавить</Button>
                                        </Col>
                                    </Row>
                                    {alertAddVisible && (
                                        <Alert message="Успешно добавлены" type="success" closable
                                               afterClose={handleAddClose}
                                               style={{marginTop: '1vw',}}/>
                                    )}
                                    {alertExcludeVisible && (
                                        <Alert message="Успешно исключен" type="success" closable
                                               afterClose={handleExcludeClose}
                                               style={{marginTop: '1vw',}}/>
                                    )}
                                </>
                            )
                        }
                    />
                )}
            </ConfigProvider>
            <Divider>Исключенные ученики</Divider>
            <ConfigProvider
                renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет учеников'/>}>
                {loadingInactive ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <>
                        <Table
                            columns={inactiveColumns}
                            dataSource={inactiveStudents}
                            pagination={false}
                            bordered
                            scroll={{x: "max-content"}}
                            style={{
                                margin: '1vw',
                            }}
                        />
                        {alertRestoreVisible && (
                            <Alert message="Успешно восстановлен" type="success" closable
                                   afterClose={handleRestoreClose}
                                   style={{margin: '1vw',}}/>
                        )}
                    </>
                )}
            </ConfigProvider>
        </div>
    )
}
