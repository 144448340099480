import {Link, useNavigate} from "react-router-dom";
import {Button, ConfigProvider, Divider, Empty, Input, Popover, QRCode, Spin, Table} from "antd";
import React, {useEffect, useState} from "react";
import {getAllUsersByRole} from "../../http/UserApi";
import {EDIT_STUDENT_ROUTE, MY_STUDENT_ROUTE, STUDENT_ROUTE} from "../../utils";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";

export default function Student() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(true);
    const [students, setStudents] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const [sort, setSort] = useState('id,asc');
    const [searchText, setSearchText] = useState(null);

    const dateTimeFormat = 'DD.MM.YYYY';

    const columns = [
        {
            title: 'Номер аккаунта',
            dataIndex: 'id',
            key: 'id',
            sorter: true
        },
        {
            title: 'Логин',
            dataIndex: 'username',
            key: 'username',
            sorter: true
        },
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
            sorter: true
        },
        {
            title: 'Телефон',
            dataIndex: 'phoneStudent',
            key: 'phoneStudent',
            sorter: true
        },
        {
            title: 'Страница ВК',
            dataIndex: 'linkVk',
            key: 'linkVk',
            sorter: true
        },
        {
            title: 'Город',
            dataIndex: 'city',
            key: 'city',
            sorter: true
        },
        {
            title: 'Дата рождения',
            dataIndex: 'birthday',
            key: 'birthday',
            sorter: true,
            align: 'center',
        },
        {
            title: 'Редактировать данные',
            dataIndex: 'editStudent',
            key: 'editStudent',
            align: 'center',
        }
    ];

    function getSortOrder(order) {
        switch (order) {
            case 'ascend':
                return 'asc';
            case 'descend':
                return 'desc';
            default:
                return 'asc';
        }
    }

    const getStudents = async () => {
        setLoadingSearch(true);
        await getAllUsersByRole(localStorage.getItem('token'), "STUDENT", pagination.current - 1, pagination.pageSize, sort, searchText).then(
            response => {
                setStudents(
                    response.data.content.map(row => ({
                        id: row.id,
                        username: row.username,
                        name: <Link to={`${STUDENT_ROUTE}/${row.id}`}
                                    className="custom-link">{row.name}</Link>,
                        phoneStudent: row.phoneStudent,
                        linkVk: <Popover overlayInnerStyle={{padding: 0}}
                                         content={<QRCode value={row.linkVk} bordered={false}/>}>
                            <Link
                                to={(row.linkVk.startsWith('http://') || row.linkVk.startsWith('https://')) ? row.linkVk : `https://${row.linkVk}`}
                                target='_blank' className="custom-link">{row.linkVk}</Link></Popover>,
                        city: row.city,
                        editStudent: <Button
                            onClick={() => navigate(`${EDIT_STUDENT_ROUTE}/${row.id}`)}>Редактировать</Button>,
                        birthday: dayjs(row.birthday).format(dateTimeFormat),
                    }))
                );
                setPagination({...pagination, total: response.data.totalElements});
                setLoading(false);
                setLoadingSearch(false);
            }).catch(
            error => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        getStudents();
    }, [pagination.current, pagination.pageSize, sort, searchText]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        if (sorter.column !== undefined && sorter.order !== undefined) {
            setSort(`${sorter.field},${getSortOrder(sorter.order)}`)
        } else {
            setSort("")
        }
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Divider>Ученики</Divider>
            <Search placeholder="Введите текст для поиска" enterButton loading={loadingSearch}
                    onSearch={event => {
                        setSearchText(event)
                    }}
                    style={{
                        padding: '0 1vw 0 1vw',
                    }}
            />
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Spin/>
                </div>
            ) : (
                <ConfigProvider
                    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет учеников'/>}>
                    <Table
                        columns={columns}
                        dataSource={students}
                        pagination={pagination}
                        bordered
                        onChange={handleTableChange}
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                </ConfigProvider>
            )}
        </div>
    )
}