import React, {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom';
import {Divider, Card, Spin} from 'antd';
import {getOrderStatus} from "../../http/OrderApi";
import {HOME_ROUTE} from "../../utils";

export default function Access() {

    const [loading, setLoading] = useState(true);
    const [orderStatus, setOrderStatus] = useState("");
    const [orderAmount, setOrderAmount] = useState("");
    const params = useParams();

    const getStatus = async () => {
        await getOrderStatus(localStorage.getItem('token'), params.id).then(
            response => {
                setOrderStatus(response.data.status);
                setOrderAmount(response.data.amount);
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    useEffect(() => {
        getStatus();
    }, []);

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <div
                style={{
                    minHeight: '100vh',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{}}
                >
                    <Divider>Статус оплаты</Divider>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Spin/>
                        </div>
                    ) : (
                        <Card
                            style={{display: "inline-block",}}
                        >
                            {orderStatus === "PAID" &&
                                <p>Оплата на сумму {orderAmount}₽ успешно осуществлена</p>
                            }
                            {orderStatus === "REGISTERED" &&
                                <p>Оплата зарегестрирована, но не оплачена</p>
                            }
                            {orderStatus !== "REGISTERED" && orderStatus !== "PAID" &&
                                <p>Неизвестный статус оплаты</p>
                            }
                            <Link to={HOME_ROUTE} className="custom-link">На главную</Link>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    )
}
